<template>
  <div class="modal video-modal">
    <a href="javascript:;" @click="handleCloseModal" class="btn-back">
          <span class="icon-arr">
            <i />
          </span>
          {{$t('back')}}
        </a>
    <div class="modal-content">
      <video ref="videoDom" :src="`https://${link}`" loop width="100%" height="100%"/>
    </div>
  </div>
</template>


<script>

export default {
  name: 'VideoModal',
  props: {
    link: {
      type: String,
      default: ''
    }
  },
  data() {
    return {

    }
  },
  mounted() {
    // 弹窗打开时，播放视频
    this.$refs.videoDom.play();
  },
  methods: {
    handleCloseModal() {
      this.$emit('close', 'showVideoModal2');
    },

  }
}
</script>

<style scoped lang="scss">
.video-modal {
  width:100%;
  height:100%;
  // background: url("./images/bg.png") no-repeat center/cover;
  // padding: vw(17) vw(62) vw(22) vw(66);

  .icon-close {
    top: vw(-40);
    right: vw(10);
  }

  video{
    object-fit: cover;
  }
  .btn-back {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: vw(18);
        color: #fff;
        position: absolute;
        left: vw(20);
        top: vw(20);
        z-index: 20;

        .icon-arr {
          width: vw(30);
          height: vw(37);
          background: url("@/assets/images/news/btn-prev.png") no-repeat center/contain;
          position: relative;
          margin-right: vw(20);
          transition: all ease-in-out 0.3s;

          i {
            position: absolute;
            top: 50%;
            margin-top: vw(-4);
            right: 0;
            width: vw(9);
            height: vw(7);
            background: url("@/assets/images/news/icon-arr-l.png") no-repeat center/contain;
            transition: all ease-in-out 0.6s;
          }
        }

        &:hover {
          .icon-arr {
            transform: translateX(vw(-10));

            i {
              transform: translateX(vw(-16));
            }
          }
        }
      }

  .modal-content {
    width: 100%;
    height: 100%;
    clip-path: polygon(
            0 vw(7),
            vw(10) 0,
            calc(100% - vw(7)) 0,
            100% vw(7),
            100% calc(100% - vw(7)),
            calc(100% - vw(7)) 100%,
            vw(10) 100%,
            0 calc(100% - vw(7))

    );
    overflow: hidden;
  }
}
</style>
