<template>
  <div class="main swiper-container" @click.once="playaudios()" id="main">
    <Header :active="showPage" ref="Header" @togglePage="handleTogglePage"  :path="'index'"/>
    <Tips v-if="showPage !== 3"/>
    <div class="swiper-wrapper">
      <div class="swiper-slide">
        <KV @openRegisterModal="handleOpenRegisterModal" @openVideoModal="handleOpenVideoModal" :isOrder="isOrder" :lang="lang" ref="kv"/>
      </div>
      <div class="swiper-slide">
        <Part1 ref="part1" @openVideoModal="handleOpenVideoModal2" @openModal="type => handleOpenIntroduceModal(type)" :lang="lang"/>
      </div>
      <div class="swiper-slide">
        <Part2 ref="part2" @openVideoModal="handleOpenVideoModal" :lang="lang" />
      </div>
      <div class="swiper-slide">
        <Part3 ref="part3" :active="showPage === 3" :lang="lang" @openModal="handshowjoinDiscussion()"/>
      </div>
    </div>
    <div v-if="showModal" class="modal-bg"/>
    <IntroduceModal v-show="showIntroduceModal" :modalType="introduceType" ref="introduceModal" @close="handleCloseModal" :lang="lang"/>
    <LoginModal v-if="showLoginModal" :isConfirmProtocol="isConfirmProtocol" :lang="lang"
                @close="handleCloseModal" @openRealName="handleOpenRealNameModal" @toggleOrder="handleToggleOrder" @toggleProtocol="handleToggleProtocol"
                @openProtocol="handleOpenProtocolModal"/>
    <RegisterModal v-if="showRegisterModal" :lang="lang" :isConfirmProtocol="isConfirmProtocol"
                   @toggleProtocol="handleToggleProtocol" @close="handleCloseModal" @openLogin="handleOpenLoginModal"
                   @openRealName="handleOpenRealNameModal" @openProtocol="handleOpenProtocolModal"/>
    <RealNameModal v-if="showRealNameModal" :lang="lang" @close="handleCloseModal"/>
    <ProtocolModal v-if="showProtocolModal" :lang="lang" @close="handleCloseProtocolModal" @confirmProtocol="handleConfirmProtocol"/>
    <VideoModal :link="videoLink" v-if="showVideoModal" @close="handleCloseModal"/>
    <VideoModal2 :link="videoLin2" v-if="showVideoModal2" @close="handleCloseModal"/>

    <JoinDiscussion v-if="showjoinDiscussion" @close="handleCloseModal('showjoinDiscussion')" :lang="lang"></JoinDiscussion>
  </div>
</template>
<script>
import Swiper from 'swiper';
import 'swiper/js/swiper.esm.js';
import 'swiper/css/swiper.min.css';
import KV from "@/views/index/components/KV/index.vue";
import Part1 from "@/views/index/components/part1/index.vue";
import Part2 from "@/views/index/components/part2/index.vue";
import Part3 from "@/views/index/components/part3/index.vue";
import Header from "@/components/Header/index.vue";
import Tips from "@/components/tips/index.vue";
import IntroduceModal from "@/components/introduce-modal/index.vue";
import {closeModal, openModal} from "@/utils/modal";
import LoginModal from "@/components/login-modal/index.vue";
import RegisterModal from "@/components/register-modal/index.vue";
import RealNameModal from "@/components/realName-modal/index.vue";
import ProtocolModal from "@/components/protocol-modal/index.vue";
import VideoModal from "@/components/video-modal/index.vue";
import VideoModal2 from "@/components/video-modal2/index.vue";
import {getCookie, setCookie} from "@/utils/utils";
import JoinDiscussion from "@/components/join-discussion/index.vue";
import {getUserinfo} from "@/api/main";
export default {
  name: 'Main',
  components: {
    VideoModal,
    ProtocolModal,
    RealNameModal,
    LoginModal,
    Header,
    Tips,
    KV,
    Part1,
    Part2,
    Part3,
    IntroduceModal,
    RegisterModal,
    JoinDiscussion,
    VideoModal2
  },
  data() {
    return {
      isConfirmProtocol: false,
      pageSwiper: null,
      showPage: 0,
      showModal: false,
      showIntroduceModal: false,
      introduceType: 0,
      showLoginModal: false,
      showRegisterModal: false,
      showRealNameModal: false,
      showProtocolModal: false,
      showVideoModal: false,
      openModalName: '',
      isOrder: false,
      videoLink: '',
      lang: this.$i18n.locale,
      showjoinDiscussion:false,
      token:null,
      tokenlog:null,
      showVideoModal2:false,
    }
  },
  watch: {
    '$i18n.locale': function(newVal) {
      this.lang = newVal; // 监听语言变化更新 isEnglish 变量
    },
  },
  created() {
    // 获取链接参数
    const path = this.$route.query.path;
    if (path) {
      this.showPage = parseInt(path);
    }
    this.tokenlog =  this.$route.query.token
    if(this.tokenlog != undefined){
      setCookie('token',this.tokenlog);
    }
    
    // 获取cookie  eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjozNiwiZXhwIjoxNzE2NzExNDM4fQ.GLauxQ3NxeU7gSAfMYZPHukRfR0A-Bf0kqr1gT7ehGs
    this.token = this.getCookie("token");
    const order = getCookie('order');
    if (order) {
      this.isOrder = true;
    }
  },
  mounted() {
    if(this.tokenlog != undefined){
      this.getUserinfoFun()
    }
    this.handleInitPageSwiper()
    // 如果存在path参数则跳转到对应页面
    if (this.showPage && this.pageSwiper && this.pageSwiper.activeIndex !== this.showPage) {
      this.pageSwiper.slideTo(this.showPage, 0, true)
    }

    document.addEventListener('readystatechange', function() {
      if (document.readyState === 'complete') {
        // console.log('所有资源已加载完成');
      }
    });
    
  },
  methods: {
    handleInitPageSwiper() {
      this.pageSwiper = new Swiper('#main', {
        direction: 'vertical',
        observer: true,
        observeSlideChildren: true,
        observeParents: true,
        keyboard: {
          enabled: true,
        },
        // shortSwipes: false,
        threshold: 60,
        mousewheel: true,
        resistance: true,
        resistanceRatio: 0,
        noSwipingSelector: '.modal-bg',
        on: {
          slideChange: () => {
            this.showPage = this.pageSwiper.activeIndex
            // 通知Part3组件显示对话框
            if (this.showPage !== 3) {
              this.$refs.part3.isShow = false
            } else {
              this.$refs.part3.isShow = true
              this.$refs.part3.handleClickJoin()
            }
            // 获取当前路由参数
            const currentParams = { ...this.$route.query };

            // 修改参数
            const newPath = this.showPage.toString(); // 确保参数是字符串类型
            if (currentParams.path !== newPath) {
              currentParams.path = newPath;
              this.$router.push({ query: currentParams }).catch(() => {});
            }
          },
        },
      });
    },
    // get
    getUserinfoFun(){
      var that = this
      getUserinfo().then(response => {
        if (response.code === 0) {
          setCookie('id', response.data.user.id);
          setCookie('name', response.data.user.name);
          setCookie('order', response.data.user.is_reservation);
          this.isOrder = true
          this.$emit('toggleOrder', response.data.user.is_reservation);

          const currentUrl = window.location.href;
          const tokenIndex = currentUrl.indexOf('token=');
          const baseUrl = currentUrl.substring(0, tokenIndex);
          const remainingUrl = currentUrl.substring(tokenIndex);
          const nextAmpersandIndex = remainingUrl.indexOf('&');
          let newUrl;
          if (nextAmpersandIndex !== -1) {
              const remainingParams = remainingUrl.substring(nextAmpersandIndex + 1);
              newUrl = baseUrl + remainingParams;
          } else {
              newUrl = baseUrl;
          }
          window.location.href = newUrl;
        }else if(response.code === 10001){
          this.isOrder = false
          this.$emit('toggleOrder', 0);
          that.$toast.error(response.msg);
          const currentUrl = window.location.href;
          const tokenIndex = currentUrl.indexOf('token=');
          const baseUrl = currentUrl.substring(0, tokenIndex);
          const remainingUrl = currentUrl.substring(tokenIndex);
          const nextAmpersandIndex = remainingUrl.indexOf('&');
          let newUrl;
          if (nextAmpersandIndex !== -1) {
              const remainingParams = remainingUrl.substring(nextAmpersandIndex + 1);
              newUrl = baseUrl + remainingParams;
          } else {
              newUrl = baseUrl;
          }
          window.location.href = newUrl;
          document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          document.cookie = "order=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        }else {
          this.$toast.error(response.msg);
        }
      });
    },
    // 获取Cookie
    getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
    },
    // 关闭弹窗
    handleCloseModal(modalName) {
      console.log(modalName)
      this.showModal = false
      this[modalName] = false
      closeModal()
    },
    // 打开介绍弹窗
    handleOpenIntroduceModal(type) {
      this.showModal = true
      this.showIntroduceModal = true
      this.introduceType = type
      this.$refs.introduceModal.getconceptstation();
      this.$nextTick(() => {
        openModal()
      })
    },
    // 打开登录弹窗
    handleOpenLoginModal() {
      this.showModal = true
      this.showLoginModal = true
      this.$nextTick(() => {
        openModal()
      })
    },
    // 打开注册弹窗
    handleOpenRegisterModal() {
      this.showModal = true
      this.showRegisterModal = true
      this.$nextTick(() => {
        openModal()
      })
    },
    // 播放音乐
    playaudios(){
      this.$refs.Header.playaudio(true);
      this.$refs.Header.internalAudio = true
    },
    // 打开讨论弹窗
    handshowjoinDiscussion() {
      this.showModal = true
      this.showjoinDiscussion = true
      this.$nextTick(() => {
        openModal()
      })
    },
    // 打开实名认证弹窗
    handleOpenRealNameModal() {
      this.showModal = true
      this.showRealNameModal = true
      this.$nextTick(() => {
        openModal()
      })
    },
    // 打开协议弹窗
    handleOpenProtocolModal(modalName) {
      this.showModal = true
      // 降低已经打开的弹窗的层级
      document.querySelector(`.${modalName}`).style.zIndex = '999'
      this.openModalName = modalName
      this.showProtocolModal = true
      this.$nextTick(() => {
        openModal()
      })
    },
    // 关闭协议弹窗
    handleCloseProtocolModal() {
      this.showProtocolModal = false
      document.querySelector(`.${this.openModalName}`).style.zIndex = '1001'
    },
    // 协议弹窗点击同意协议
    handleConfirmProtocol() {
      this.isConfirmProtocol = true
      this.handleCloseProtocolModal()
    },
    // 切换协议状态
    handleToggleProtocol() {
      this.isConfirmProtocol = !this.isConfirmProtocol
    },
    // handshowjoinDiscussion() {
    //   this.showjoinDiscussion = !this.showjoinDiscussion
    // },
    // 点击按钮切换页面
    handleTogglePage(index) {
      this.pageSwiper.slideTo(index, 1000, true)
    },
    // 切换预约按钮状态
    handleToggleOrder(isOrder, time) {
      this.isOrder = true
      setCookie('order', isOrder, time);
    },
    // 打开视频弹窗
    handleOpenVideoModal(link) {
      this.showModal = true
      this.showVideoModal = true
      this.videoLink = link
      var that =this
      // setTimeout(function(){
      //   that.$refs.Header.playaudio(false);
      //   that.$refs.Header.internalAudio = false
      // },100)
      this.$nextTick(() => {
        openModal()
      })
    },
    handleOpenVideoModal2(link) {
      this.showModal = true
      this.showVideoModal2 = true
      this.videoLin2 = link
      this.$nextTick(() => {
        openModal()
      })
    },
  }
}
</script>
<style scoped lang="scss">
.main {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;

  * {
    box-sizing: border-box;
  }
}
</style>
