<template>
  <div class="modal realName-modal">
    <a href="javascript:;" class="icon-close" @click="handleCloseModal"/>
    <div class="modal-content">
      <input class="input" type="text"
             v-model="realData.real_name" placeholder="请填写您的真实姓名"/>
      <input class="input" type="text"
             v-model="realData.idcard_number" placeholder="请填写您的身份证号"/>
      <a href="javascript:;" class="btn-sub" @click="handleSub"/>
    </div>
  </div>
</template>


<script>
import {isEmail, isIdCard, isPhone, setCookie} from "@/utils/utils";
import {getEmailCode, getPhoneCode, login, realName} from "@/api/main";
import Tracker from "@/utils/tracker";
export default {
  name: 'RealNameModal',
  props: {},
  data() {
    return {
      realData: {
        real_name: '',
        idcard_number: ''
      }
    }
  },
  mounted() {

  },
  methods: {
    handleCloseModal() {
      this.$emit('close', 'showRealNameModal');
    },
    // 点击提交
    handleSub() {
      if (!this.realData.real_name) {
        this.$toast.error('请填写您的真实姓名');
        return false;
      }
      if (!this.realData.idcard_number) {
        this.$toast.error('请填写您的身份证号');
        return false;
      }
      if (!isIdCard(this.realData.idcard_number)) {
        this.$toast.error('身份证号格式不正确');
        return false;
      }
      Tracker.handleCloseModal_view()
      const params = {
        real_name: this.realData.real_name,
        idcard_number: this.realData.idcard_number
      }
      realName(params).then(response => {
        if (response.code === 0) {
          if (response.data.code === 0) {
            if (response.data.data.result === 0) {
              this.$toast.success('实名认证成功');
              this.$emit('close', 'showRealNameModal');
            } else {
              this.$toast.error('实名认证失败，请检查信息是否正确');
            }
          } else {
            this.$toast.error('实名认证失败，请检查信息是否正确');
          }
        } else {
          this.$toast.error(res.msg);
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.realName-modal {
  width: vw(676);
  height: vw(373);
  background: url("./images/bg.png") no-repeat center/cover;
  padding: vw(124) vw(67) vw(22) vw(67);

  .icon-close {
    top: vw(41);
    right: 0;
  }

  .input {
    width: vw(380);
    height: vw(44);
    border: vw(1) solid #418d95;
    line-height: vw(42);
    font-size: vw(16);
    padding-left: vw(15);
    background: transparent;
    color: #fff;
    display: block;
    margin: 0 auto vw(9);

    &::placeholder {
      color: #999;
    }
  }

  .btn-sub {
    display: block;
    width: vw(167);
    height: vw(76);
    background: url("./images/btn-submit.png") no-repeat center/contain;
    margin: vw(28) auto 0;
  }

}
</style>
