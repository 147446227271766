<template>
  <div :class="['modal', 'protocol-modal', lang === 'en' && 'protocol-modal-en']">
    <a href="javascript:;" class="icon-close" @click="handleCloseModal"/>
    <div class="modal-content">
      <p>{{lang === 'en' ? 'Please read the following text carefully' : '请您仔细阅读下述文本'}}</p>
      <p v-if="lang === 'zh-CN'">并确认是否同意<a href="javascript:;" class="link">服务条款</a>和<a href="javascript:;" class="link">隐私协议</a></p>
      <p v-if="lang === 'en'">and confirm whether you agree to the<a href="javascript:;" class="link">Terms of Service</a>and<a href="javascript:;" class="link">Privacy Policy</a></p>
      <div class="btn-box">
        <a href="javascript:;" class="btn-confirm" @click="handleConfirmProtocol" />
        <a href="javascript:;" class="btn-cancel" @click="handleCloseModal" />
      </div>
    </div>
  </div>
</template>


<script>
import {isEmail, isPhone, setCookie} from "@/utils/utils";
import {getEmailCode, getPhoneCode, login, realName} from "@/api/main";
import Tracker from "@/utils/tracker";
export default {
  name: 'ProtocolModal',
  props: {
    lang: {
      type: String,
      default: 'zh-CN'
    }
  },
  data() {
    return {

    }
  },
  mounted() {

  },
  methods: {
    handleCloseModal() {
      this.$emit('close', 'showProtocolModal');
    },
    // 点击同意协议
    handleConfirmProtocol() {
      Tracker.handleConfirmProtocol_view()
      this.$emit('confirmProtocol');
    },
  }
}
</script>

<style scoped lang="scss">
.protocol-modal {
  width: vw(612);
  height: vw(373);
  background: url("./images/bg.png") no-repeat center/cover;
  padding: vw(129) vw(67) vw(22) vw(67);

  .icon-close {
    top: vw(41);
    right: 0;
  }

  p {
    font-size: vw(18);
    color: #fff;
    line-height: vw(32);
    text-align: center;

    a {
      color: #63f6fb;
    }
  }

  .btn-box {
    width: vw(370);
    margin: vw(30) auto 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .btn-confirm {
      width: vw(167);
      height: vw(76);
      background: url("./images/btn-yes.png") no-repeat center/contain;
    }

    .btn-cancel {
      width: vw(166);
      height: vw(50);
      background: url("./images/btn-no.png") no-repeat center/contain;
    }
  }

  &.protocol-modal-en {
    width: vw(676);
    padding-left: vw(131);
    background-image: url("./images/en/bg.png");

    .btn-box {
      .btn-confirm {
        background-image: url("./images/en/btn-yes.png");
      }
      .btn-cancel {
        background-image: url("./images/en/btn-no.png");
      }
    }
  }
}
</style>
