<template>
  <div :class="['part', 'part-3', 'show']">
    <div :class="['main', isShow && 'space']">
      <h1 class="title" />
      <ul v-show="isShow" class="left" id="left" ref="messageList">
        <li><img src="./images/img-message1.png" alt="" /></li>
        <li><img src="./images/img-message2.png" alt="" /></li>
        <li><img src="./images/img-message3.png" alt="" /></li>
        <li><img src="./images/img-message4.png" alt="" /></li>
        <li><img src="./images/img-message5.png" alt="" /></li>
        <li><img src="./images/img-message6.png" alt="" /></li>
        <li><img src="./images/img-message7.png" alt="" /></li>
        <li><img src="./images/img-message8.png" alt="" /></li>
        <li><img src="./images/img-message9.png" alt="" /></li>
        <li><img src="./images/img-message10.png" alt="" /></li>
        <li><img src="./images/img-message11.png" alt="" /></li>
        <li><img src="./images/img-message12.png" alt="" /></li>
        <li><img src="./images/img-message13.png" alt="" /></li>
        <li><img src="./images/img-message14.png" alt="" /></li>
        <li><img src="./images/img-message15.png" alt="" /></li>
        <li><img src="./images/img-message16.png" alt="" /></li>
        <li><img src="./images/img-message17.png" alt="" /></li>
        <li><img src="./images/img-message18.png" alt="" /></li>
        <li><img src="./images/img-message19.png" alt="" /></li>
        <li><img src="./images/img-message20.png" alt="" /></li>
        <li><img src="./images/img-message21.png" alt="" /></li>
        <li><img src="./images/img-message22.png" alt="" /></li>
        <li><img src="./images/img-message23.png" alt="" /></li>
        <li><img src="./images/img-message24.png" alt="" /></li>
        <li><img src="./images/img-message25.png" alt="" /></li>
        <li><img src="./images/img-message26.png" alt="" /></li>
        <li><img src="./images/img-message27.png" alt="" /></li>
        <li><img src="./images/img-message28.png" alt="" /></li>

      </ul>
      <div :class="['right']">
        <h2 class="tit"/>
        <ul class="role-list">
          <li class="li"><img src="./images/icon-role1.png" alt=""/></li>
          <li class="li"><img src="./images/icon-role2.png" alt=""/></li>
          <li class="li"><img src="./images/icon-role3.png" alt=""/></li>
          <li class="li"><img src="./images/icon-role4.png" alt=""/></li>
          <li class="li"><img src="./images/icon-role5.png" alt=""/></li>
          <li class="li"><img src="./images/icon-role6.png" alt=""/></li>
          <li class="li"><img src="./images/icon-role7.png" alt=""/></li>
          <li class="li"><img src="./images/icon-role8.png" alt=""/></li>
          <li class="li"><img src="./images/icon-role9.png" alt=""/></li>
        </ul>
        <a href="javascript:;" class="btn-join" @click="diahandleClickJoin" />
      </div>
    </div>
  </div>
</template>

<script>
import {handleHoverBtn} from "@/utils/btnHoverAni";
import Tracker from "@/utils/tracker";
export default {
  name: 'Part3',
  props: {
    lang: {
      type: String,
      default: 'zh-CN'
    }
  },
  data(){
    return {
      isShow: false
    }
  },
  created() {

  },
  mounted() {
    document.getElementById('left').addEventListener('wheel', function(event) {
      event.stopPropagation();
    }, { passive: false });
    handleHoverBtn('.btn-join')
    if (this.isShow) this.handleClickJoin()
  },
  methods: {
    // 点击显示对话
    handleClickJoin() {
      // 右侧隐藏动画结束后显示
      setTimeout(() => {
        this.isShow = true
        this.$nextTick(() => {
          // 逐个改变.left下的li的opacity，间隔1s
          let lis = this.$refs.messageList.querySelectorAll('li');
          let left = this.$refs.messageList
          var heightli = 0
          lis.forEach((li, index) => {
            setTimeout(() => {
              li.style.opacity = 1
              if(index>=3){
                var height = li.getBoundingClientRect().height;
                heightli += height
                left.scrollTop =  heightli;
              }
            }, index * 1500)
          })
        })
      }, 500)
    },
    diahandleClickJoin(type) {
      Tracker.diahandleClickJoin_view()
      this.$emit('openModal', type);
    }
  }
}
</script>

<style scoped lang="scss">

.part-3 {
  background: url("https://cos.enmmo.yepboys.com/images/pc-bg-part3.jpg") no-repeat center/cover;

  &::before {
    opacity: 0;
    content: '';
    width: vw(1880);
    height: vw(1004);
    background: url("./images/line.png") no-repeat center/cover;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -45%);
    pointer-events: none;
    transition: opacity linear 1s;
  }

  &.show {
    &::before {
      opacity: 1;
    }
  }

  &::after {
    content: '';
    width: vw(1377);
    height: vw(166);
    background: url("./images/img-slogan.png") no-repeat center/cover;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    opacity: 0.2;
    pointer-events: none;
  }

  .main {
    width: vw(1300);
    height: vw(700);
    margin-top: vw(-400);
    margin-left: vw(-650);
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    justify-content: center;
    padding-top: vw(158);

    &.space {
      justify-content: space-between;
    }


    .title {
      width: vw(181);
      height: vw(89);
      background: url("./images/tit.png") no-repeat center/contain;
      position: absolute;
      left: 50%;
      margin-left: vw(-45);
      top: vw(20);
    }

    .left {
      width: vw(805);
      overflow: auto;
      &::-webkit-scrollbar {
        width: 0;
      }
      &::-webkit-scrollbar-track {
        border-radius: 0;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 0;
      }

      li {
        opacity: 0;
        transition: opacity linear 1s;
        img {
          display: block;
          width: vw(804);
        }
      }
    }

    .right {
      width: vw(420);
      height: vw(600);

      &.hidden {
        opacity: 0;
        transition: all linear 0.2s;
      }

      .tit {
        display: block;
        width: vw(276);
        height: vw(61);
        margin: 0 auto vw(20);
        background: url("./images/tit2.png") no-repeat center/contain;
      }

      .role-list {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;

        li {
          margin-right: vw(30);
          margin-bottom: vw(20);

          &:nth-child(3n) {
            margin-right: 0;
          }

          img {
            display: block;
            width: vw(120);
            height: vw(146);
            object-fit: contain;
          }
        }
      }

      .btn-join {
        display: block;
        width: vw(207);
        height: vw(72);
        background: url("./images/btn-join.png") no-repeat center/contain;
        margin:  0 auto;
        filter: url('#noise');
        margin-top: vw(20);
      }
    }
  }
}

</style>

