<template>
  <div id="app" :class="lang === 'en' ? 'app-en' : 'app-cn'">
    <div class="loading" v-show="loading">
      <video preload muted id="loading_Video">
        <source :src="lang === 'en' ? en_video : cn_video" type="video/mp4">
      </video>
    </div>
    <router-view v-show="!loading" />
  </div>
</template>

<script>
import Main from "@/views/index/index";
export default {
  name: 'App',
  components: {
    Main
  },
  data(){
    return {
      loading: true,
      lang: this.$i18n.locale,
      cn_video: 'https://cos.enmmo.yepboys.com/video/loading-pc-cn.mp4',
      en_video: 'https://cos.enmmo.yepboys.com/video/loading-pc-en.mp4'
    }
  },
  watch: {
    '$i18n.locale': function (val) {
      // 清除播放记录
      localStorage.removeItem('loading');
    }
  },
  mounted(){
    // 判断是否播放过视频
    if (localStorage.getItem('loading') === 'false'){
      this.loading = false;
    } else {
      this.loading = true;
      // 播放视频
      document.getElementById('loading_Video').play();
      // 监听视频播放结束
      document.getElementById('loading_Video').addEventListener('ended', this.videoEnd, false);
    }
  },
  methods: {
    videoEnd() {
      this.loading = false;
      // 只播放一次，储存到localStorage
      localStorage.setItem('loading', 'false');
    }
  }
}
</script>

<style lang="scss">

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  text-align: left;
  width: 100vw;
  height: 100vh;

  &.app-en {
    //font-family: 'AGENCYR', Avenir, Helvetica, Arial, sans-serif;
  }

  &.app-cn {
    font-family: 'siyuan', Avenir, Helvetica, Arial, sans-serif;
  }

  #loading_Video {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999;
  }
}

</style>
