// import { isIos, isAndroid } from './util.js'
/**
 * 发送自定义上报时间
 * @param {*} eventName 事件名称
 * @param {*} eventParams 自定义参数
 */
const send = function (eventName, eventParams = {}) {
  // console.log('触发ga')
  gtag('event', eventName, {
    ...eventParams
  })
}
// SLS UV上报
// SLS 页面耗时
export const observer = new PerformanceObserver((list) => {
  list.getEntries().forEach((entry) => {
    const res = entry.toJSON()
    if (res) {
      localStorage.setItem('domComplete', res?.domComplete ?? 0)
      localStorage.setItem('domInteractive', res?.domInteractive ?? 0)
      reportSls({
        domComplete: res?.domComplete ?? 0,
        domInteractive: res?.domInteractive ?? 0,
      })
    }
  });
});
const reportSls = (data, ip = '') => {
  if (window.SLS_Tracker) {
    const tracker = new SLS_Tracker({
      project: 'tsh-pub-common-0001',
      logstore: 'cbt-xgame-cn',
      host: 'cn-shanghai.log.aliyuncs.com',
    })
    const event_value1 = localStorage.getItem('visitorId') || ''
    const initReport = {
      "user_agent": navigator.userAgent,
      "ip_address": ip,
      "from": "others",
      "way": "direct",
      "event_value1": event_value1
    }
    const newData = {
      ...data,
      created_at: new Date().getTime(),
      store: isIos() ? 'itunes' : isAndroid() ? 'google' : 'pc'
    }
    tracker.send({...initReport, ...newData})
  }
}

const Tracker = {
  page_view: function(){
    send('page_view')
    reportSls({ event_name: 'page_view' })
  },
  openRegisterModal_view: function(){
    send('openRegisterModal_view')
    reportSls({ event_name: 'openRegisterModal_view' })
  },
  handleOpenVideoModal_view: function(){
    send('handleOpenVideoModal_view')
    reportSls({ event_name: 'handleOpenVideoModal_view' })
  },
  linkUrl_view: function(){
    send('linkUrl_view')
    reportSls({ event_name: 'linkUrl_view' })
  },
  handleClickOpenModal_view: function(){
    send('handleClickOpenModal_view')
    reportSls({ event_name: 'handleClickOpenModal_view' })
  },
  handleClickTab_view: function(){
    send('handleClickTab_view')
    reportSls({ event_name: 'handleClickTab_view' })
  },
  handleViewDetails_view: function(){
    send('handleViewDetails_view')
    reportSls({ event_name: 'handleViewDetails_view' })
  },
  gettokenTo_view: function(){
    send('gettokenTo_view')
    reportSls({ event_name: 'gettokenTo_view' })
  },
  diahandleClickJoin_view: function(){
    send('diahandleClickJoin_view')
    reportSls({ event_name: 'diahandleClickJoin_view' })
  },
  handleClickNav_view: function(){
    send('handleClickNav_view')
    reportSls({ event_name: 'handleClickNav_view' })
  },
  changeLanguage_view: function(){
    send('changeLanguage_view')
    reportSls({ event_name: 'changeLanguage_view' })
  },
  handleConfirmProtocol_view: function(){
    send('handleConfirmProtocol_view')
    reportSls({ event_name: 'handleConfirmProtocol_view' })
  },
  handleGetCode_view: function(){
    send('handleGetCode_view')
    reportSls({ event_name: 'handleGetCode_view' })
  },
  handleToggleMethod_view: function(){
    send('handleToggleMethod_view')
    reportSls({ event_name: 'handleToggleMethod_view' })
  },
  login_view: function(){
    send('login_view')
    reportSls({ event_name: 'login_view' })
  },
  handleProtocol_view: function(){
    send('handleProtocol_view')
    reportSls({ event_name: 'handleProtocol_view' })
  },
  handleCloseModal_view: function(){
    send('handleCloseModal_view')
    reportSls({ event_name: 'handleCloseModal_view' })
  },
  getPhoneCode_view: function(){
    send('getPhoneCode_view')
    reportSls({ event_name: 'getPhoneCode_view' })
  },
  getMailCode_view: function(){
    send('getMailCode_view')
    reportSls({ event_name: 'getMailCode_view' })
  },
  handleReceiveInfo_view: function(){
    send('handleReceiveInfo_view')
    reportSls({ event_name: 'handleReceiveInfo_view' })
  },
  handleNext_view: function(){
    send('handleNext_view')
    reportSls({ event_name: 'handleNext_view' })
  },
  backTop_view: function(){
    send('backTop_view')
    reportSls({ event_name: 'backTop_view' })
  },
  handleOpenLoginModal_view: function(){
    send('handleOpenLoginModal_view')
    reportSls({ event_name: 'handleOpenLoginModal_view' })
  },
  getEmailCode_view: function(){
    send('getEmailCode_view')
    reportSls({ event_name: 'getEmailCode_view' })
  },
  handleTabChange_view: function(){
    send('handleTabChange_view')
    reportSls({ event_name: 'handleTabChange_view' })
  },
}

export default Tracker