<template>
  <div :class="['part, part-1', lang === 'en' && 'part-1-en']">
    <video src="https://cos.chmmo.yepboys.com/video/part2-bg.mp4" class="video-bg" loop autoplay muted/>
    <div class="main" id="part1-main">
      <h1 class="title"/>
      <a href="javascript:;" class="btn-go btn-go1" @click="handleClickOpenModal(0)">
        <span class="t1" :data-text="lang === 'en' ? 'Worldview' : '世界观'" />
        <span class="t2" />
        <span class="t3" />
      </a>
      <a href="javascript:;" class="btn-go btn-go2" @click="handleClickOpenModal(1)">
        <span class="t1" :data-text="lang === 'en' ? 'Product concept' :  '产品概念'"/>
        <span class="t2" />
        <span class="t3" />
      </a>
      <router-link to="/race" class="btn-go btn-go3">
        <span class="t1" :data-text="lang === 'en' ? 'Race Introduction' : '种族介绍'"/>
        <span class="t2" />
        <span class="t3" />
      </router-link>
      <a href="javascript:;" @click="handleOpenVideoModal" :class="['btn-go4', isAllLoaded ? `btn-show btn-go4-${ainIndex}` : 'btn-go4-one']" />
<!--      <router-link to="/ship" href="javascript:;" :class="['btn-go4', isAllLoaded ? `btn-show btn-go4-${ainIndex}` : 'btn-go4-one']" />-->
    </div>
  </div>
</template>

<script>
// 导入threeJS
import * as THREE from 'three';
import {openModal} from "@/utils/modal";
import Tracker from "@/utils/tracker";
export default {
  name: 'Part1',
  components: {},
  props: {
    lang: {
      type: String,
      default: 'zh-CN'
    }
  },
  data() {
    return {
      showModal: false,
      showModalType: 0,
      ainIndex: 0,
      isAllLoaded: false,
      videoLink: 'cos.chmmo.yepboys.com/video/Fight_ShipV1.mp4'
    }
  },
  mounted() {
    this.$nextTick(() => {
      // this.initThree();
      this.preloadImages(74, this.handleImageLoad)
    });
  },
  watch: {
    lang: function() {
      this.ainIndex = 0
    }
  },
  methods: {
    // 打开视频弹窗
    handleOpenVideoModal() {
      Tracker.handleOpenVideoModal_view()
      this.$emit('openVideoModal', this.videoLink)
    },
    handleCreateFrameAnimation(length) {
      let lastTime = 0; // 上次执行动画的时间戳

      const animate = (currentTime) => {
        const deltaTime = currentTime - lastTime; // 计算时间差

        // 如果时间差大于某个阈值（例如16ms，即60帧每秒），执行动画
        if (deltaTime > 32) {
          lastTime = currentTime; // 更新上次执行动画的时间戳
          if (this.ainIndex >= length) {
            this.ainIndex = 0;
          } else {
            this.ainIndex++;
          }
        }

        // 通过 requestAnimationFrame 方法递归调用自身
        requestAnimationFrame(animate);
      };

      // 第一次调用动画函数
      animate();
    },
    preloadImages(length, callback) {
      const url = this.lang === 'en' ? 'https://cos.enmmo.yepboys.com/sprites/btn-ship-en-sprites.png' : 'https://cos.enmmo.yepboys.com/sprites/btn-ship-cn-sprites.png';
      const img = new Image();
      img.src = url;
      img.onload = () => {
        callback(length)
      };
    },
    handleImageLoad(length) {
      this.isAllLoaded = true;
      this.handleCreateFrameAnimation(length)
    },

    initThree() {
      const ww = document.getElementById('part1-main').clientWidth;
      const wh = document.getElementById('part1-main').clientHeight;

      // 创建场景
      let scene = new THREE.Scene();
      // 创建相机
      let camera = new THREE.PerspectiveCamera(90, ww / wh, 0.1, 1000);
      // 创建渲染器
      let renderer = new THREE.WebGLRenderer({antialias: true, alpha: true});
      // 设置渲染器的大小
      renderer.setSize(ww, wh);
      // 将渲染器添加到页面
      document.getElementById('part1-main').appendChild(renderer.domElement);
      // 加载纹理
      const textureLoader = new THREE.TextureLoader();
      const texture = textureLoader.load(require('./images/center.png'));
      const depthTexture = textureLoader.load(require('./images/center-depth.png'));
      // 鼠标坐标
      let mouse = new THREE.Vector2();
      // 创建平面
      let geometry = new THREE.PlaneGeometry(10.13, 8.07);
      const material = new THREE.ShaderMaterial({
        uniforms: {
          uTexture: {value: texture},
          uDepthTexture: {value: depthTexture},
          uMouse: {value: mouse},
        },
        vertexShader: `
        varying vec2 vUv;
        void main() {
            vUv = uv;
            gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
        `,
        fragmentShader: `
        uniform sampler2D uTexture;
        uniform sampler2D uDepthTexture;
        uniform vec2 uMouse;
        varying vec2 vUv;

        void main() {
            vec4 color = texture2D(uTexture, vUv);
            vec4 depth = texture2D(uDepthTexture, vUv);
            float depthValue = depth.r;
            float x = vUv.x + uMouse.x * 0.002 * depthValue; // 减少鼠标移动时的影响范围
            float y = vUv.y + uMouse.y * 0.002 * depthValue; // 减少鼠标移动时的影响范围
            vec4 newColor = texture2D(uTexture, vec2(x, y));
            if (newColor.a < 0.5) {
                discard; // 丢弃透明像素
            }
            gl_FragColor = mix(color, newColor, 0.8); // 调整混合比例
        }
        `,
        blending: THREE.NormalBlending // 设置混合模式
      });


      // 创建网格
      let plane = new THREE.Mesh(geometry, material);
      // 将网格添加到场景
      scene.add(plane);
      // 设置相机的位置
      camera.position.set(0, 0, 4.6);

      //监听屏幕变化
      window.addEventListener('resize', onWindowResize, false);

      function onWindowResize() {
        const ww = document.getElementById('part1-main').clientWidth;
        const wh = document.getElementById('part1-main').clientHeight;
        camera.aspect = ww / wh;
        camera.updateProjectionMatrix();
        renderer.setSize(ww, wh);
      }

      // 渲染
      function animate() {
        requestAnimationFrame(animate);
        renderer.render(scene, camera);
      }

      animate()

      // 鼠标移动事件
      document.addEventListener('mousemove', (e) => {
        mouse.x = (e.clientX / window.innerWidth) * 1.5 - 1;
        mouse.y = -(e.clientY / window.innerHeight) * 1.5 + 1;
      });
    },

    // 打开弹窗
    handleClickOpenModal(type) {
      Tracker.handleClickOpenModal_view()
      this.$emit('openModal', type);
    }
  },
}
</script>

<style scoped lang="scss">

.part-1 {
  height: 100vh;

  .video-bg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none;
  }

  .main {
    width: vw(1013);
    height: vw(807);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-48%, -50%);
    background: transparent;


    &::before {
      content: '';
      width: vw(1011);
      height: vw(776);
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background: url("https://cos.enmmo.yepboys.com/images/img-ship-pc.png") no-repeat center/contain;
      z-index: 1;
      image-rendering: auto;
      image-rendering: crisp-edges;
      image-rendering: pixelated;
    }

    .title {
      width: vw(231);
      height: vw(89);
      background: url("./images/tit.png") no-repeat center/contain;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 0;
      z-index: 2;
    }

    .btn-go {
      position: absolute;
      height: vw(60);
      z-index: 10;

      &::before {
        pointer-events: none;
        content: '';
        width: vw(203);
        height: vw(4);
        position: absolute;
        left: 0;
        opacity: 0;
        top: vw(20);
        background: url("./images/icon-line1.png") no-repeat center/contain;
        transition: all 400ms cubic-bezier(0.2, 0, 0.7, 1);
      }

      &::after {
        pointer-events: none;
        content: '';
        width: 0;
        height: vw(1);
        position: absolute;
        left: 0;
        top: vw(42);
        background: url("./images/icon-line2.png") no-repeat center/contain;
        transition: all 400ms cubic-bezier(0.2, 0, 0.7, 1);

      }

      .t1 {
        display: block;
        height: vw(29);
        margin-bottom: vw(4);
        position: relative;
        z-index: 3;
        pointer-events: none;

        &::before {
          width: 120%;
          content: attr(data-text);
          position: absolute;
          font-size: vw(28);
          top: vw(-1);
          letter-spacing: vw(2.5);
          text-shadow: 1px 0 blue;
          color: white;
          font-family: 'zt', Avenir, Helvetica, Arial, sans-serif;
          overflow: hidden;
          clip: rect(0, 900px, 0, 0);
        }

        &::after {
          width: 120%;
          content: attr(data-text);
          position: absolute;
          font-size: vw(28);
          top: vw(-1);
          letter-spacing: vw(2.5);
          text-shadow: -1px 0 red;
          color: white;
          overflow: hidden;
          font-family: 'zt', Avenir, Helvetica, Arial, sans-serif;
          clip: rect(0, 900px, 0, 0);
        }
      }

      .t2 {
        height: vw(18);
        position: absolute;
        top: vw(10);
        left: 0;
        opacity: 0;
        z-index: 1;
        pointer-events: none;
        transition: all 400ms cubic-bezier(0.2, 0, 0.7, 1);
      }

      .t3 {
        pointer-events: none;
        display: block;
        width: vw(218);
        height: vw(21);
        margin-left: vw(-5);
        position: relative;
        z-index: 2;
        transition: box-shadow 400ms cubic-bezier(0.2, 0, 0.7, 1), transform 200ms cubic-bezier(0.2, 0, 0.7, 1);
      }

      &.btn-go1 {
        left: vw(-90);
        top: vw(340);

        .t1 {
          width: vw(120);
          background: url("./images/btn-go1-t1.png") no-repeat center/contain;

          &::before, &::after{
            left: vw(27);
          }
        }

        .t2 {
          width: vw(93);
          background: url("./images/btn-go1-t2.png") no-repeat center/contain;
        }

        .t3 {
          background: url("./images/btn-go1-t3.png") no-repeat center/contain;
        }
      }

      &.btn-go2 {
        top: vw(190);
        right: vw(-20);

        .t1 {
          width: vw(163);
          background: url("./images/btn-go2-t1.png") no-repeat center/contain;

          &::before, &::after {
            left: vw(37);
          }
        }

        .t2 {
          width: vw(142);
          background: url("./images/btn-go2-t2.png") no-repeat center/contain;
        }

        .t3 {
          background: url("./images/btn-go2-t3.png") no-repeat center/contain;
        }
      }

      &.btn-go3 {
        top: vw(470);
        right: vw(120);

        .t1 {
          width: vw(161);
          background: url("./images/btn-go3-t1.png") no-repeat center/contain;

          &::before, &::after{
            left: vw(33);
          }
        }

        .t2 {
          width: vw(151);
          background: url("./images/btn-go3-t2.png") no-repeat center/contain;
        }

        .t3 {
          background: url("./images/btn-go3-t3.png") no-repeat center/contain;
        }
      }

      @keyframes shake {
        0% {
          transform: translateX(0) rotate(0);
        }
        20% {
          transform: translateX(-5px) rotate(-2deg);
        }
        40% {
          transform: translateX(5px) rotate(2deg);
        }
        60% {
          transform: translateX(-3px) rotate(-1deg);
        }
        80% {
          transform: translateX(3px) rotate(1deg);
        }
        100% {
          transform: translateX(0) rotate(0);
        }
      }

      &:hover {
        &::before {
          filter: brightness(150%);
          opacity: 1;
          top: vw(3);
        }

        &::after {
          width: vw(285);
        }

        .t1 {
          //animation: shake 0.5s ease-in-out 2;

          &::before {
            animation: noise-anim-2 3s infinite linear alternate-reverse;
          }

          &::after {
            animation: noise-anim 2s infinite linear alternate-reverse;
          }
        }

        .t2 {
          opacity: 1;
          left: vw(125);
        }

        .t3 {
          box-shadow:
              0 0 20px rgba(100, 245, 251, 0.5),
              0 0 40px rgba(100, 245, 251, 0.3),
              0 0 60px rgba(100, 245, 251, 0.2),
              0 0 80px rgba(100, 245, 251, 0.07),
              0 0 100px rgba(100, 245, 251, 0.08);
        }

      }
    }

    @keyframes noise-anim {
      0% {
        clip: rect(77px, 9999px, 51px, 0);
      }
      5% {
        clip: rect(25px, 9999px, 30px, 0);
      }
      10% {
        clip: rect(70px, 9999px, 75px, 0);
      }
      15% {
        clip: rect(94px, 9999px, 96px, 0);
      }
      20% {
        clip: rect(24px, 9999px, 11px, 0);
      }
      25% {
        clip: rect(12px, 9999px, 53px, 0);
      }
      30% {
        clip: rect(79px, 9999px, 54px, 0);
      }
      35% {
        clip: rect(70px, 9999px, 93px, 0);
      }
      40% {
        clip: rect(73px, 9999px, 86px, 0);
      }
      45% {
        clip: rect(100px, 9999px, 96px, 0);
      }
      50% {
        clip: rect(5px, 9999px, 68px, 0);
      }
      55% {
        clip: rect(6px, 9999px, 16px, 0);
      }
      60% {
        clip: rect(4px, 9999px, 60px, 0);
      }
      65% {
        clip: rect(86px, 9999px, 74px, 0);
      }
      70% {
        clip: rect(6px, 9999px, 53px, 0);
      }
      75% {
        clip: rect(41px, 9999px, 35px, 0);
      }
      80% {
        clip: rect(70px, 9999px, 60px, 0);
      }
      85% {
        clip: rect(52px, 9999px, 66px, 0);
      }
      90% {
        clip: rect(18px, 9999px, 16px, 0);
      }
      95% {
        clip: rect(71px, 9999px, 80px, 0);
      }
      100% {
        clip: rect(69px, 9999px, 51px, 0);
      }
    }

    @keyframes noise-anim-2 {
      0% {
        clip: rect(20px, 9999px, 7px, 0);
      }
      5% {
        clip: rect(51px, 9999px, 73px, 0);
      }
      10% {
        clip: rect(33px, 9999px, 21px, 0);
      }
      15% {
        clip: rect(60px, 9999px, 48px, 0);
      }
      20% {
        clip: rect(86px, 9999px, 48px, 0);
      }
      25% {
        clip: rect(32px, 9999px, 50px, 0);
      }
      30% {
        clip: rect(59px, 9999px, 50px, 0);
      }
      35% {
        clip: rect(80px, 9999px, 96px, 0);
      }
      40% {
        clip: rect(7px, 9999px, 39px, 0);
      }
      45% {
        clip: rect(62px, 9999px, 90px, 0);
      }
      50% {
        clip: rect(85px, 9999px, 84px, 0);
      }
      55% {
        clip: rect(89px, 9999px, 51px, 0);
      }
      60% {
        clip: rect(88px, 9999px, 50px, 0);
      }
      65% {
        clip: rect(8px, 9999px, 82px, 0);
      }
      70% {
        clip: rect(80px, 9999px, 23px, 0);
      }
      75% {
        clip: rect(22px, 9999px, 52px, 0);
      }
      80% {
        clip: rect(96px, 9999px, 44px, 0);
      }
      85% {
        clip: rect(84px, 9999px, 27px, 0);
      }
      90% {
        clip: rect(32px, 9999px, 71px, 0);
      }
      95% {
        clip: rect(85px, 9999px, 35px, 0);
      }
      100% {
        clip: rect(24px, 9999px, 23px, 0);
      }
    }

    .btn-go4 {
      width: vw(303);
      height: vw(220);
      position: absolute;
      top: vw(460);
      left: vw(40);
      z-index: 10;
    }

    .btn-go4-one {
      background: url("./images/btn-go4.png") no-repeat center/contain;
    }

    .btn-show {
      background-size: vw(45450/2) vw(440/2);
      background-image: url("https://cos.enmmo.yepboys.com/sprites/btn-ship-cn-sprites.png");
      background-repeat: no-repeat;
    }

    .btn-go4-42 {
      background-position: vw(0) vw(0);
    }
    .btn-go4-43 {
      background-position: vw(-606/2) vw(0);
    }
    .btn-go4-44 {
      background-position: vw(-1212/2) vw(0);
    }
    .btn-go4-45 {
      background-position: vw(-1818/2) vw(0);
    }
    .btn-go4-46 {
      background-position: vw(-2424/2) vw(0);
    }
    .btn-go4-47 {
      background-position: vw(-3030/2) vw(0);
    }
    .btn-go4-48 {
      background-position: vw(-3636/2) vw(0);
    }
    .btn-go4-49 {
      background-position: vw(-4242/2) vw(0);
    }
    .btn-go4-50 {
      background-position: vw(-4848/2) vw(0);
    }
    .btn-go4-52 {
      background-position: vw(-5454/2) vw(0);
    }
    .btn-go4-51 {
      background-position: vw(-6060/2) vw(0);
    }
    .btn-go4-53 {
      background-position: vw(-6666/2) vw(0);
    }
    .btn-go4-54 {
      background-position: vw(-7272/2) vw(0);
    }
    .btn-go4-55 {
      background-position: vw(-7878/2) vw(0);
    }
    .btn-go4-57 {
      background-position: vw(-8484/2) vw(0);
    }
    .btn-go4-56 {
      background-position: vw(-9090/2) vw(0);
    }
    .btn-go4-59 {
      background-position: vw(-9696/2) vw(0);
    }
    .btn-go4-60 {
      background-position: vw(-10302/2) vw(0);
    }
    .btn-go4-58 {
      background-position: vw(-10908/2) vw(0);
    }
    .btn-go4-61 {
      background-position: vw(-11514/2) vw(0);
    }
    .btn-go4-63 {
      background-position: vw(-12120/2) vw(0);
    }
    .btn-go4-62 {
      background-position: vw(-12726/2) vw(0);
    }
    .btn-go4-64 {
      background-position: vw(-13332/2) vw(0);
    }
    .btn-go4-65 {
      background-position: vw(-13938/2) vw(0);
    }
    .btn-go4-66 {
      background-position: vw(-14544/2) vw(0);
    }
    .btn-go4-67 {
      background-position: vw(-15150/2) vw(0);
    }
    .btn-go4-69 {
      background-position: vw(-15756/2) vw(0);
    }
    .btn-go4-68 {
      background-position: vw(-16362/2) vw(0);
    }
    .btn-go4-70 {
      background-position: vw(-16968/2) vw(0);
    }
    .btn-go4-71 {
      background-position: vw(-17574/2) vw(0);
    }
    .btn-go4-73 {
      background-position: vw(-18180/2) vw(0);
    }
    .btn-go4-72 {
      background-position: vw(-18786/2) vw(0);
    }
    .btn-go4-74 {
      background-position: vw(-19392/2) vw(0);
    }
    .btn-go4-1 {
      background-position: vw(-19998/2) vw(0);
    }
    .btn-go4-3 {
      background-position: vw(-20604/2) vw(0);
    }
    .btn-go4-2 {
      background-position: vw(-21210/2) vw(0);
    }
    .btn-go4-4 {
      background-position: vw(-21816/2) vw(0);
    }
    .btn-go4-6 {
      background-position: vw(-22422/2) vw(0);
    }
    .btn-go4-0 {
      background-position: vw(-23028/2) vw(0);
    }
    .btn-go4-5 {
      background-position: vw(-23634/2) vw(0);
    }
    .btn-go4-10 {
      background-position: vw(-24240/2) vw(0);
    }
    .btn-go4-7 {
      background-position: vw(-24846/2) vw(0);
    }
    .btn-go4-8 {
      background-position: vw(-25452/2) vw(0);
    }
    .btn-go4-9 {
      background-position: vw(-26058/2) vw(0);
    }
    .btn-go4-12 {
      background-position: vw(-26664/2) vw(0);
    }
    .btn-go4-17 {
      background-position: vw(-27270/2) vw(0);
    }
    .btn-go4-11 {
      background-position: vw(-27876/2) vw(0);
    }
    .btn-go4-13 {
      background-position: vw(-28482/2) vw(0);
    }
    .btn-go4-15 {
      background-position: vw(-29088/2) vw(0);
    }
    .btn-go4-14 {
      background-position: vw(-29694/2) vw(0);
    }
    .btn-go4-16 {
      background-position: vw(-30300/2) vw(0);
    }
    .btn-go4-18 {
      background-position: vw(-30906/2) vw(0);
    }
    .btn-go4-19 {
      background-position: vw(-31512/2) vw(0);
    }
    .btn-go4-20 {
      background-position: vw(-32118/2) vw(0);
    }
    .btn-go4-22 {
      background-position: vw(-32724/2) vw(0);
    }
    .btn-go4-24 {
      background-position: vw(-33330/2) vw(0);
    }
    .btn-go4-21 {
      background-position: vw(-33936/2) vw(0);
    }
    .btn-go4-23 {
      background-position: vw(-34542/2) vw(0);
    }
    .btn-go4-27 {
      background-position: vw(-35148/2) vw(0);
    }
    .btn-go4-25 {
      background-position: vw(-35754/2) vw(0);
    }
    .btn-go4-26 {
      background-position: vw(-36360/2) vw(0);
    }
    .btn-go4-28 {
      background-position: vw(-36966/2) vw(0);
    }
    .btn-go4-29 {
      background-position: vw(-37572/2) vw(0);
    }
    .btn-go4-31 {
      background-position: vw(-38178/2) vw(0);
    }
    .btn-go4-32 {
      background-position: vw(-38784/2) vw(0);
    }
    .btn-go4-30 {
      background-position: vw(-39390/2) vw(0);
    }
    .btn-go4-33 {
      background-position: vw(-39996/2) vw(0);
    }
    .btn-go4-36 {
      background-position: vw(-40602/2) vw(0);
    }
    .btn-go4-35 {
      background-position: vw(-41208/2) vw(0);
    }
    .btn-go4-34 {
      background-position: vw(-41814/2) vw(0);
    }
    .btn-go4-37 {
      background-position: vw(-42420/2) vw(0);
    }
    .btn-go4-40 {
      background-position: vw(-43026/2) vw(0);
    }
    .btn-go4-38 {
      background-position: vw(-43632/2) vw(0);
    }
    .btn-go4-41 {
      background-position: vw(-44238/2) vw(0);
    }
    .btn-go4-39 {
      background-position: vw(-44844/2) vw(0);
    }




  }

  &.part-1-en {
    .main {
      .title {
        width: vw(291);
        height: vw(72);
        background-image: url("./images/en/tit.png");
      }

      .btn-go {
        .t1 {
          &::before {
            font-family: 'AGENCYR', Avenir, Helvetica, Arial, sans-serif;
            letter-spacing: vw(2.5);
          }

          &::after {
            font-family: 'AGENCYR', Avenir, Helvetica, Arial, sans-serif;
            letter-spacing: vw(2.6);
          }
        }
        &.btn-go1 {

          .t1 {
            width: vw(137);
            background: url("./images/en/btn-go1-t1.png") no-repeat center/contain;

            &::before, &::after {
              top: vw(-3);
            }
          }

        }

        &.btn-go2 {
          .t1 {
            width: vw(220);
            background: url("./images/en/btn-go2-t1.png") no-repeat center/contain;

            &::before, &::after {
              top: vw(-3);
            }
          }
        }

        &.btn-go3 {
          .t1 {
            width: vw(234);
            background: url("./images/en/btn-go3-t1.png") no-repeat center/contain;

            &::before, &::after {
              top: vw(-3);
              left: vw(35);
              letter-spacing: vw(2.7);
            }
          }
        }
      }

      .btn-go4-one {
        background: url("./images/en/btn-go4.png") no-repeat center/contain;
      }

      .btn-show {
        background-size: vw(45450/2) vw(440/2);
        background-image: url("https://cos.enmmo.yepboys.com/sprites/btn-ship-en-sprites.png");
        background-repeat: no-repeat;
      }

      .btn-go4-42 {
        background-position: vw(-0/2) vw(0);
      }
      .btn-go4-43 {
        background-position: vw(-606/2) vw(0);
      }
      .btn-go4-44 {
        background-position: vw(-1212/2) vw(0);
      }
      .btn-go4-45 {
        background-position: vw(-1818/2) vw(0);
      }
      .btn-go4-46 {
        background-position: vw(-2424/2) vw(0);
      }
      .btn-go4-47 {
        background-position: vw(-3030/2) vw(0);
      }
      .btn-go4-48 {
        background-position: vw(-3636/2) vw(0);
      }
      .btn-go4-49 {
        background-position: vw(-4242/2) vw(0);
      }
      .btn-go4-50 {
        background-position: vw(-4848/2) vw(0);
      }
      .btn-go4-51 {
        background-position: vw(-5454/2) vw(0);
      }
      .btn-go4-52 {
        background-position: vw(-6060/2) vw(0);
      }
      .btn-go4-53 {
        background-position: vw(-6666/2) vw(0);
      }
      .btn-go4-54 {
        background-position: vw(-7272/2) vw(0);
      }
      .btn-go4-55 {
        background-position: vw(-7878/2) vw(0);
      }
      .btn-go4-56 {
        background-position: vw(-8484/2) vw(0);
      }
      .btn-go4-57 {
        background-position: vw(-9090/2) vw(0);
      }
      .btn-go4-58 {
        background-position: vw(-9696/2) vw(0);
      }
      .btn-go4-59 {
        background-position: vw(-10302/2) vw(0);
      }
      .btn-go4-60 {
        background-position: vw(-10908/2) vw(0);
      }
      .btn-go4-61 {
        background-position: vw(-11514/2) vw(0);
      }
      .btn-go4-63 {
        background-position: vw(-12120/2) vw(0);
      }
      .btn-go4-62 {
        background-position: vw(-12726/2) vw(0);
      }
      .btn-go4-64 {
        background-position: vw(-13332/2) vw(0);
      }
      .btn-go4-65 {
        background-position: vw(-13938/2) vw(0);
      }
      .btn-go4-67 {
        background-position: vw(-14544/2) vw(0);
      }
      .btn-go4-66 {
        background-position: vw(-15150/2) vw(0);
      }
      .btn-go4-68 {
        background-position: vw(-15756/2) vw(0);
      }
      .btn-go4-69 {
        background-position: vw(-16362/2) vw(0);
      }
      .btn-go4-70 {
        background-position: vw(-16968/2) vw(0);
      }
      .btn-go4-71 {
        background-position: vw(-17574/2) vw(0);
      }
      .btn-go4-73 {
        background-position: vw(-18180/2) vw(0);
      }
      .btn-go4-72 {
        background-position: vw(-18786/2) vw(0);
      }
      .btn-go4-0 {
        background-position: vw(-19392/2) vw(0);
      }
      .btn-go4-74 {
        background-position: vw(-19998/2) vw(0);
      }
      .btn-go4-1 {
        background-position: vw(-20604/2) vw(0);
      }
      .btn-go4-2 {
        background-position: vw(-21210/2) vw(0);
      }
      .btn-go4-3 {
        background-position: vw(-21816/2) vw(0);
      }
      .btn-go4-4 {
        background-position: vw(-22422/2) vw(0);
      }
      .btn-go4-5 {
        background-position: vw(-23028/2) vw(0);
      }
      .btn-go4-8 {
        background-position: vw(-23634/2) vw(0);
      }
      .btn-go4-6 {
        background-position: vw(-24240/2) vw(0);
      }
      .btn-go4-7 {
        background-position: vw(-24846/2) vw(0);
      }
      .btn-go4-9 {
        background-position: vw(-25452/2) vw(0);
      }
      .btn-go4-10 {
        background-position: vw(-26058/2) vw(0);
      }
      .btn-go4-12 {
        background-position: vw(-26664/2) vw(0);
      }
      .btn-go4-11 {
        background-position: vw(-27270/2) vw(0);
      }
      .btn-go4-13 {
        background-position: vw(-27876/2) vw(0);
      }
      .btn-go4-15 {
        background-position: vw(-28482/2) vw(0);
      }
      .btn-go4-18 {
        background-position: vw(-29088/2) vw(0);
      }
      .btn-go4-17 {
        background-position: vw(-29694/2) vw(0);
      }
      .btn-go4-19 {
        background-position: vw(-30300/2) vw(0);
      }
      .btn-go4-14 {
        background-position: vw(-30906/2) vw(0);
      }
      .btn-go4-16 {
        background-position: vw(-31512/2) vw(0);
      }
      .btn-go4-21 {
        background-position: vw(-32118/2) vw(0);
      }
      .btn-go4-22 {
        background-position: vw(-32724/2) vw(0);
      }
      .btn-go4-20 {
        background-position: vw(-33330/2) vw(0);
      }
      .btn-go4-24 {
        background-position: vw(-33936/2) vw(0);
      }
      .btn-go4-25 {
        background-position: vw(-34542/2) vw(0);
      }
      .btn-go4-23 {
        background-position: vw(-35148/2) vw(0);
      }
      .btn-go4-29 {
        background-position: vw(-35754/2) vw(0);
      }
      .btn-go4-27 {
        background-position: vw(-36360/2) vw(0);
      }
      .btn-go4-26 {
        background-position: vw(-36966/2) vw(0);
      }
      .btn-go4-28 {
        background-position: vw(-37572/2) vw(0);
      }
      .btn-go4-31 {
        background-position: vw(-38178/2) vw(0);
      }
      .btn-go4-30 {
        background-position: vw(-38784/2) vw(0);
      }
      .btn-go4-32 {
        background-position: vw(-39390/2) vw(0);
      }
      .btn-go4-36 {
        background-position: vw(-39996/2) vw(0);
      }
      .btn-go4-33 {
        background-position: vw(-40602/2) vw(0);
      }
      .btn-go4-34 {
        background-position: vw(-41208/2) vw(0);
      }
      .btn-go4-35 {
        background-position: vw(-41814/2) vw(0);
      }
      .btn-go4-37 {
        background-position: vw(-42420/2) vw(0);
      }
      .btn-go4-38 {
        background-position: vw(-43026/2) vw(0);
      }
      .btn-go4-39 {
        background-position: vw(-43632/2) vw(0);
      }
      .btn-go4-40 {
        background-position: vw(-44238/2) vw(0);
      }
      .btn-go4-41 {
        background-position: vw(-44844/2) vw(0);
      }

    }
  }
}
</style>
<style lang="scss">
canvas {
  position: relative;
  z-index: 5;
  background: transparent;
}
</style>
