import axios from 'axios'
import {getCookie} from "@/utils/utils";

// create an axios instance
const service_xj = axios.create({
  baseURL: 'https://en.mmo.yepboys.com/server/', // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000 // request timeout
})

// request interceptor
// service_xj.interceptors.request.use(
//   config => {
//     // do something before the request is sent
//     const token = getCookie('token')
//     if (token) {
//       config.headers['Authorization'] = token
//     }
//     return config
//   },
//   error => {
//     // do something with request error
//     console.log(error) // for debug
//     return Promise.reject(error)
//   }
// )

// 防止连续点击
let lastTime = new Date().getTime();
let currentTime = new Date().getTime();
let timeOut = 1000;
service_xj.interceptors.request.use(
    config => {
      const queryString = window.location.href;
      // 找到 '?' 之后的查询部分
      const queryIndex = queryString.indexOf('?');
      const queryPart = queryIndex !== -1 ? queryString.substring(queryIndex + 1) : '';
      // 分割查询参数
      const queryParams = queryPart.split('&');
      // 遍历查询参数，寻找 'token'
      let tokenValue = null;
      queryParams.forEach(param => {
        const [key, value] = param.split('=');
        if (key === 'token') {
          tokenValue = value;
        }
      });
      
      currentTime = new Date().getTime();
      if (currentTime - lastTime < timeOut &&  tokenValue === null ) {
        // console.log(currentTime,lastTime,timeOut)
        alert('操作太频繁，请稍后再试');
        return false
      } else {
        lastTime = new Date().getTime();
        const token = getCookie('token')
        if (token) {
          config.headers['Authorization'] = token
        }
        return config;

        // 打印 token 值
      }
    },
    error => {
      console.log(error) // for debug
      return Promise.reject(error)
    }
);

// response interceptor
service_xj.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {
      const res = response.data
      if (res.code !== 0) {
        if (res.code === 40010 || res.code === 40012 ) {
          alert(res.msg)
          return false
        }else if(res.code === 10001){
          return res
        }else {
          return Promise.reject(new Error(res.msg || 'Error'))
        }
      } else {
        return res
      }
    },
    error => {
      console.log('err' + error) // for debug
      return Promise.reject(error)
    }
)

export default service_xj
