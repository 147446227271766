<template>
  <div :class="['part', 'part-2', lang === 'en' && 'part-2-en']">
    <div class="main">
      <h1 class="title" />
      <div  class="btn-change btn-change-prev">
        <i class="icon-arr icon-arr-l" />
      </div>
      <div  class="btn-change btn-change-next">
        <i class="icon-arr icon-arr-r" />
      </div>
      <a href="javascript:;" @click="gettokenTo()" :class="['btn-go', isAllLoaded ? `btn-show icon-bbs${ainIndex}` : 'btn-go-one']"/>
      <div v-if="showBanner" class="news-swiper swiper-container">
        <div class="swiper-wrapper">
          <div v-for="(item, index) in bannerList" :key="index" class="swiper-slide" data-swiper-parallax="-130%">
            <div class="img-banner">
              <div v-if="showAnimate" class="glitch__item" :style="{backgroundImage: 'url(' + `https://${item.brief.img_pc}` + ')'}" />
              <div v-if="showAnimate" class="glitch__item glitch__item2" :style="{backgroundImage: 'url(' + `https://${item.brief.img_pc}` + ')'}" />
              <img :src="`https://${item.brief.img_pc}`" alt=""/>
            </div>
            <div class="info">
              <p class="time" data-swiper-parallax="-200">{{item.brief.time}}</p>
              <h6 class="tit" data-swiper-parallax="-300">{{item.brief.title}}</h6>
              <p class="des" data-swiper-parallax="-100">{{item.brief.descr}}</p>
              <a v-if="item.brief.extra_link !== ''" :href="item.brief.extra_link" class="btn-look"/>
            </div>
          </div>
        </div>
      </div>
      <div class="index">
        <span class="current">{{showNewsIndex + 1}}</span>
        /
        <span>{{bannerList.length}}</span>
      </div>
      <div class="swiper-pagination"></div>
      <div class="info-list-box">
        <div class="info-list-top">
          <div class="list-tab">
            <a v-for="(tab, index) in infoList" :key="index" @click="handleClickTab(index)" href="javascript:;" :class="['tab', index === showInfoTabIndex && 'active']">{{tab.title}}</a>
          </div>
          <router-link :to="{path:'/list'}" class="icon-more"/>
        </div>
        <div v-if="showInfo" class="info-list-center">
          <div class="info-list">
            <div v-for="(item, index) in infoList[showInfoTabIndex].list" :key="index" class="info-item" @click="handleViewDetails(item)">
              <div class="cover">
                <img :src="`https://${item.article.thumbnail}`" alt="" class="cover" />
              </div>
              <div class="text">
                <span :class="['label', item.article.content_type === 2 ? 'label-video' : 'label-img']" />
                <p>{{parseTime(item.article.updated_at)}}</p>
              </div>
              <h6>{{item.article.title}}</h6>
            </div>
          </div>
        </div>
        <span class="line" />
      </div>
    </div>
  </div>
</template>

<script>
import {getBannerList, getInfoList} from "@/api";
import {parseTime} from "@/utils/utils";
import {handleHoverBtn} from "@/utils/btnHoverAni";
import Tracker from "@/utils/tracker";
export default {
  name: 'Part2',
  props: {
    lang: {
      type: String,
      default: 'zh-CN'
    },
  },
  data(){
    return {
      pageSwiper: null,
      showNewsIndex: 0,
      showInfoTabIndex: 0,
      showAnimate: false,
      bannerList: [],
      infoList: [],
      showBanner: false,
      showInfo: false,
      ainIndex: 0,
      isAllLoaded: false,
    }
  },
  created() {
    this.infoList = this.$t('infoList')
  },
  watch: {
    lang: function() {
      this.infoList = this.$t('infoList')
      this.getBannerList()
      this.getInfoList()
      this.preloadImages(95, this.handleImageLoad)
      this.ainIndex = 0
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.getBannerList()
      this.getInfoList()
      this.preloadImages(95, this.handleImageLoad)
    })
  },
  methods: {
    parseTime,
    handleInitPageSwiper() {
      this.pageSwiper = new this.$swiper('.news-swiper', {
        // autoplay: true,
        loop:true,
        observer: true,
        observeSlideChildren: true,
        observeParents: true,
        effect : 'fade',
        fadeEffect: {
          crossFade: true,
        },
        parallax : true,
        pagination: {
          el: ".swiper-pagination",
          type: 'bullets',
          bulletClass: 'list-pagination',
          bulletActiveClass: 'list-pagination-active',
        },
        navigation: {
          nextEl: ".btn-change-next",
          prevEl: ".btn-change-prev",
        },
        on: {
          slideChangeTransitionStart: () =>{
            this.showAnimate = true
          },
          slideChangeTransitionEnd: () =>{
            this.showAnimate = false
            this.$nextTick(() => {
              this.showNewsIndex = this.pageSwiper.realIndex
            })

          },
        },
      });
    },
    handleClickTab(index) {
      Tracker.handleClickTab_view()
      this.showInfoTabIndex = index
    },
    // 获取轮播图数据
    getBannerList() {
      let params = null
      if (this.lang === 'en') {
        params = {
          lang: 'en'
        }
      }
      getBannerList(params).then(response => {
        if (response.code === 0) {
          this.showBanner = true
          // 倒序排列
          if(response.data.item_list != null){
            this.bannerList = response.data.item_list
            this.$nextTick(() => {
              this.handleInitPageSwiper()
              handleHoverBtn('.btn-look')
            })
          }
        }
      })
    },
    // 获取资讯列表
    getInfoList() {
      let params = null
      if (this.lang === 'en') {
        params = {
          lang: 'en'
        }
      }
      getInfoList(params).then(response => {
        if (response.code === 0) {
          this.showInfo = true
          if(response.data.news!=null){
            this.infoList[1].list = response.data.news
          }
          if(response.data.announcement!=null){
            this.infoList[0].list = response.data.announcement
          }
          if(response.data.strategy!=null){
            this.infoList[2].list = response.data.strategy
          }
          if(response.data.activity!=null){
            this.infoList[3].list = response.data.activity
          }
        }
      })
    },
    // 点击查看新闻详情
    handleViewDetails(item) {
      Tracker.handleViewDetails_view()
      // extra_link为空时在新窗口打开详情页
      if (item.article.extra_link === '') {
        // 判断是否是视频
        if (item.article.content_type === 2) {
          this.$emit('openVideoModal', item.article.video_url)
        } else {
          // const url = this.$router.resolve({path:'/details', query: {id: item.article.id}})
          // window.open(url.href, '_blank')
          this.$router.push({ path: '/details', query: { id: item.article.id } })
        }
      } else {
        window.open(item.article.extra_link)
      }
    },
    handleCreateFrameAnimation(length) {
      let lastTime = 0; // 上次执行动画的时间戳

      const animate = (currentTime) => {
        const deltaTime = currentTime - lastTime; // 计算时间差

        // 如果时间差大于某个阈值（例如16ms，即60帧每秒），执行动画
        if (deltaTime > 32) {
          lastTime = currentTime; // 更新上次执行动画的时间戳
          if (this.ainIndex >= length) {
            this.ainIndex = 0;
          } else {
            this.ainIndex++;
          }
        }

        // 通过 requestAnimationFrame 方法递归调用自身
        requestAnimationFrame(animate);
      };

      // 第一次调用动画函数
      animate();
    },
    preloadImages(length, callback) {
      const url = this.lang === 'en' ? 'https://cos.enmmo.yepboys.com/sprites/btn-bbs-en-sprites.png' : 'https://cos.enmmo.yepboys.com/sprites/btn-bbs-cn-sprites.png';
      const img = new Image();
      img.src = url;
      img.onload = () => {
        callback(length)
      };
    },
    handleImageLoad(length) {
      this.isAllLoaded = true;
      this.handleCreateFrameAnimation(length)
    },
    gettokenTo(){
      Tracker.gettokenTo_view()
      this.token = this.getCookie("token");
      if(this.token != undefined){
        window.location.href = 'https://enbbs.yepboys.com/#/index?token=' + this.token ;
      }else{
        window.location.href = 'https://enbbs.yepboys.com/#/index' ;
      }
    },
    getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
    },
  }
}
</script>

<style scoped lang="scss">

.part-2 {
  background: url("./images/bg.png") no-repeat center/cover;

  .main {
    width: vw(1840);
    height: vw(720);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -52%);

    &::before {
      content: '';
      width: vw(1860);
      height: vw(720);
      background: url("./images/bg-line.png") no-repeat center/cover;
      position: absolute;
      top: 0;
      left: vw(-11.5);
      pointer-events: none;
      z-index: 5;
    }

    .title {
      width: vw(191);
      height: vw(89);
      background: url("./images/tit.png") no-repeat center/contain;
      position: absolute;
      right: vw(500);
      transform: translateX(-50%);
      top: vw(28);
      z-index: 5;
    }

    .index {
      display: flex;
      width: vw(70);
      position: absolute;
      left: vw(50);
      bottom: vw(40);
      z-index: 5;
      font-size: vw(30);
      color: #fff;

      span {
        margin: 0 vw(5);
      }

      .current {
        color: #64f5fb;
        font-weight: bold;
      }
    }

    .btn-go {
      width: vw(295);
      height: vw(108);
      background-repeat: no-repeat;
      position: absolute;
      right: vw(-30);
      top: vw(20);
      z-index: 5;
      transform: scale(0.8);
      //animation: showScale infinite linear 2s;

      &:hover {
        transition: positon 0.1s linear;
        top: vw(16);
      }

      &.btn-go-one {
        background-size: contain;
        background-position: center;
        background-image: url("./images/btn-go.png");
      }

      &.btn-show {
        background-size: vw(56640/2) vw(216/2);
        background-image: url("https://cos.enmmo.yepboys.com/sprites/btn-bbs-cn-sprites.png");
      }
    }

    .icon-bbs0 {
      background-position: vw(0) vw(0);
    }
    .icon-bbs1 {
      background-position: vw(-590/2) vw(0);
    }
    .icon-bbs2 {
      background-position: vw(-1180/2) vw(0);
    }
    .icon-bbs3 {
      background-position: vw(-1770/2) vw(0);
    }
    .icon-bbs4 {
      background-position: vw(-2360/2) vw(0);
    }
    .icon-bbs5 {
      background-position: vw(-2950/2) vw(0);
    }
    .icon-bbs6 {
      background-position: vw(-3540/2) vw(0);
    }
    .icon-bbs7 {
      background-position: vw(-4130/2) vw(0);
    }
    .icon-bbs8 {
      background-position: vw(-4720/2) vw(0);
    }
    .icon-bbs9 {
      background-position: vw(-5310/2) vw(0);
    }
    .icon-bbs10 {
      background-position: vw(-5900/2) vw(0);
    }
    .icon-bbs11 {
      background-position: vw(-6490/2) vw(0);
    }
    .icon-bbs12 {
      background-position: vw(-7080/2) vw(0);
    }
    .icon-bbs13 {
      background-position: vw(-7670/2) vw(0);
    }
    .icon-bbs14 {
      background-position: vw(-8260/2) vw(0);
    }
    .icon-bbs15 {
      background-position: vw(-8850/2) vw(0);
    }
    .icon-bbs16 {
      background-position: vw(-9440/2) vw(0);
    }
    .icon-bbs17 {
      background-position: vw(-10030/2) vw(0);
    }
    .icon-bbs18 {
      background-position: vw(-10620/2) vw(0);
    }
    .icon-bbs19 {
      background-position: vw(-11210/2) vw(0);
    }
    .icon-bbs20 {
      background-position: vw(-11800/2) vw(0);
    }
    .icon-bbs21 {
      background-position: vw(-12390/2) vw(0);
    }
    .icon-bbs22 {
      background-position: vw(-12980/2) vw(0);
    }
    .icon-bbs23 {
      background-position: vw(-13570/2) vw(0);
    }
    .icon-bbs24 {
      background-position: vw(-14160/2) vw(0);
    }
    .icon-bbs25 {
      background-position: vw(-14750/2) vw(0);
    }
    .icon-bbs26 {
      background-position: vw(-15340/2) vw(0);
    }
    .icon-bbs27 {
      background-position: vw(-15930/2) vw(0);
    }
    .icon-bbs28 {
      background-position: vw(-16520/2) vw(0);
    }
    .icon-bbs29 {
      background-position: vw(-17110/2) vw(0);
    }
    .icon-bbs30 {
      background-position: vw(-17700/2) vw(0);
    }
    .icon-bbs31 {
      background-position: vw(-18290/2) vw(0);
    }
    .icon-bbs32 {
      background-position: vw(-18880/2) vw(0);
    }
    .icon-bbs33 {
      background-position: vw(-19470/2) vw(0);
    }
    .icon-bbs34 {
      background-position: vw(-20060/2) vw(0);
    }
    .icon-bbs35 {
      background-position: vw(-20650/2) vw(0);
    }
    .icon-bbs36 {
      background-position: vw(-21240/2) vw(0);
    }
    .icon-bbs37 {
      background-position: vw(-21830/2) vw(0);
    }
    .icon-bbs38 {
      background-position: vw(-22420/2) vw(0);
    }
    .icon-bbs41 {
      background-position: vw(-23010/2) vw(0);
    }
    .icon-bbs39 {
      background-position: vw(-23600/2) vw(0);
    }
    .icon-bbs40 {
      background-position: vw(-24190/2) vw(0);
    }
    .icon-bbs42 {
      background-position: vw(-24780/2) vw(0);
    }
    .icon-bbs43 {
      background-position: vw(-25370/2) vw(0);
    }
    .icon-bbs45 {
      background-position: vw(-25960/2) vw(0);
    }
    .icon-bbs44 {
      background-position: vw(-26550/2) vw(0);
    }
    .icon-bbs48 {
      background-position: vw(-27140/2) vw(0);
    }
    .icon-bbs49 {
      background-position: vw(-27730/2) vw(0);
    }
    .icon-bbs46 {
      background-position: vw(-28320/2) vw(0);
    }
    .icon-bbs47 {
      background-position: vw(-28910/2) vw(0);
    }
    .icon-bbs50 {
      background-position: vw(-29500/2) vw(0);
    }
    .icon-bbs51 {
      background-position: vw(-30090/2) vw(0);
    }
    .icon-bbs53 {
      background-position: vw(-30680/2) vw(0);
    }
    .icon-bbs52 {
      background-position: vw(-31270/2) vw(0);
    }
    .icon-bbs54 {
      background-position: vw(-31860/2) vw(0);
    }
    .icon-bbs55 {
      background-position: vw(-32450/2) vw(0);
    }
    .icon-bbs56 {
      background-position: vw(-33040/2) vw(0);
    }
    .icon-bbs59 {
      background-position: vw(-33630/2) vw(0);
    }
    .icon-bbs57 {
      background-position: vw(-34220/2) vw(0);
    }
    .icon-bbs62 {
      background-position: vw(-34810/2) vw(0);
    }
    .icon-bbs61 {
      background-position: vw(-35400/2) vw(0);
    }
    .icon-bbs60 {
      background-position: vw(-35990/2) vw(0);
    }
    .icon-bbs58 {
      background-position: vw(-36580/2) vw(0);
    }
    .icon-bbs63 {
      background-position: vw(-37170/2) vw(0);
    }
    .icon-bbs65 {
      background-position: vw(-37760/2) vw(0);
    }
    .icon-bbs64 {
      background-position: vw(-38350/2) vw(0);
    }
    .icon-bbs68 {
      background-position: vw(-38940/2) vw(0);
    }
    .icon-bbs66 {
      background-position: vw(-39530/2) vw(0);
    }
    .icon-bbs67 {
      background-position: vw(-40120/2) vw(0);
    }
    .icon-bbs69 {
      background-position: vw(-40710/2) vw(0);
    }
    .icon-bbs70 {
      background-position: vw(-41300/2) vw(0);
    }
    .icon-bbs71 {
      background-position: vw(-41890/2) vw(0);
    }
    .icon-bbs72 {
      background-position: vw(-42480/2) vw(0);
    }
    .icon-bbs73 {
      background-position: vw(-43070/2) vw(0);
    }
    .icon-bbs74 {
      background-position: vw(-43660/2) vw(0);
    }
    .icon-bbs75 {
      background-position: vw(-44250/2) vw(0);
    }
    .icon-bbs80 {
      background-position: vw(-44840/2) vw(0);
    }
    .icon-bbs77 {
      background-position: vw(-45430/2) vw(0);
    }
    .icon-bbs76 {
      background-position: vw(-46020/2) vw(0);
    }
    .icon-bbs78 {
      background-position: vw(-46610/2) vw(0);
    }
    .icon-bbs81 {
      background-position: vw(-47200/2) vw(0);
    }
    .icon-bbs79 {
      background-position: vw(-47790/2) vw(0);
    }
    .icon-bbs83 {
      background-position: vw(-48380/2) vw(0);
    }
    .icon-bbs84 {
      background-position: vw(-48970/2) vw(0);
    }
    .icon-bbs82 {
      background-position: vw(-49560/2) vw(0);
    }
    .icon-bbs88 {
      background-position: vw(-50150/2) vw(0);
    }
    .icon-bbs85 {
      background-position: vw(-50740/2) vw(0);
    }
    .icon-bbs86 {
      background-position: vw(-51330/2) vw(0);
    }
    .icon-bbs87 {
      background-position: vw(-51920/2) vw(0);
    }
    .icon-bbs90 {
      background-position: vw(-52510/2) vw(0);
    }
    .icon-bbs89 {
      background-position: vw(-53100/2) vw(0);
    }
    .icon-bbs91 {
      background-position: vw(-53690/2) vw(0);
    }
    .icon-bbs92 {
      background-position: vw(-54280/2) vw(0);
    }
    .icon-bbs93 {
      background-position: vw(-54870/2) vw(0);
    }
    .icon-bbs94 {
      background-position: vw(-55460/2) vw(0);
    }
    .icon-bbs95 {
      background-position: vw(-56050/2) vw(0);
    }


    // 呼吸效果
    @keyframes showScale {
      0% {
        transform: scale(1);
        opacity: 1;
      }
      50% {
        transform: scale(1.02);
        opacity: 0.8;
      }
      100% {
        transform: scale(1);
        opacity: 1;
      }
    }

    .swiper-pagination {
      width: vw(16);
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: vw(95);
      z-index: 5;
    }

    .btn-change {
      position: absolute;
      top: 50%;
      margin-top: vw(-18);
      width: vw(30);
      height: vw(37);
      z-index: 5;
      cursor: pointer;
      transition: all ease-in-out 0.3s;

      .icon-arr {
        position: absolute;
        top: 50%;
        margin-top: vw(-4);
        width: vw(9);
        height: vw(7);
        transition: all ease-in-out 0.6s;

        &.icon-arr-l {
          background: url("./images/icon-arr-l.png") no-repeat center/contain;
          right: 0;
        }

        &.icon-arr-r {
          background: url("./images/icon-arr-r.png") no-repeat center/contain;
          left: 0;
        }
      }

      &.btn-change-prev {
        left: vw(40);
        background: url("./images/btn-prev.png") no-repeat center/contain;

        &:hover {
          transform: translateX(vw(-10));

          .icon-arr-l {
            transform: translateX(vw(-16));
          }
        }
      }

      &.btn-change-next {
        right: vw(40);
        background: url("./images/btn-next.png") no-repeat center/contain;

        &:hover {
          transform: translateX(vw(10));

          .icon-arr-r {
            transform: translateX(vw(16));
          }
        }
      }
    }

    .news-swiper {
      width: 100%;
      height: 100%;
      position: relative;

      .swiper-slide {
        width: 100%;
        height: 100%;
        border: 2px solid #637387;
        position: relative;

        .img-banner {
          width: 100%;
          height: 100%;
          position: relative;

          &::before {
            content: '';
            width: vw(970);
            height: 100%;
            background: url("./images/img-banner.png") no-repeat center/cover;
            position: absolute;
            top: 0;
            right: 0;
            pointer-events: none;
            z-index: 2;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: block;
            image-rendering: auto;
            image-rendering: crisp-edges;
            image-rendering: pixelated;
          }

          .glitch__item {
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            background-color: transparent;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            position: absolute;
            background-blend-mode: none;
            animation-duration: 2s;
            //animation-delay: 2s;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
            animation-name: glitch-anim;
            z-index: 1;
            pointer-events: none;

            &.glitch__item2 {
              animation-name: glitch-anim2;
            }
          }

          @keyframes glitch-anim {
            0% {
              opacity: 1;
              transform: translate3d(20px,0, 0);
              clip-path: polygon(0 2%, 100% 2%, 100% 5%, 0 5%);
            }
            2% {
              clip-path: polygon(0 15%, 100% 15%, 100% 15%, 0 15%);
            }
            4% {
              clip-path: polygon(0 10%, 100% 10%, 100% 20%, 0 20%);
            }
            6% {
              clip-path: polygon(0 1%, 100% 1%, 100% 2%, 0 2%);
            }
            8% {
              clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
            }
            10% {
              clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
            }
            12% {
              clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
            }
            14% {
              clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
            }
            16% {
              clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
            }
            18% {
              clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
            }
            20% {
              clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
            }
            21.9% {
              opacity: 1;
              transform: translate3d(20px, 0, 0);
            }
            22%, 100% {
              opacity: 0;
              transform: translate3d(0, 0, 0);
              clip-path: polygon(0 0, 0 0, 0 0, 0 0);
            }
          }

          @keyframes glitch-anim2 {
            0% {
              opacity: 1;
              transform: translate3d(-20px, 0, 0); /* 向左移动 10px */
              clip-path: polygon(0 2%, 100% 2%, 100% 5%, 0 5%);
            }
            /* 将每个关键帧的时间间隔调整为更短，以增加动画频率 */
            1% {
              clip-path: polygon(0 15%, 100% 15%, 100% 15%, 0 15%);
            }
            2% {
              clip-path: polygon(0 10%, 100% 10%, 100% 20%, 0 20%);
            }
            3% {
              clip-path: polygon(0 1%, 100% 1%, 100% 2%, 0 2%);
            }
            4% {
              clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
            }
            5% {
              clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
            }
            6% {
              clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
            }
            7% {
              clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
            }
            8% {
              clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
            }
            9% {
              clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
            }
            10% {
              clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
            }
            10.95% {
              opacity: 1;
              transform: translate3d(-20px, 0, 0); /* 返回到起始位置 */
            }
            11%, 100% {
              opacity: 0;
              transform: translate3d(0, 0, 0);
              clip-path: polygon(0 0, 0 0, 0 0, 0 0);
            }
          }

        }

        .info {
          position: absolute;
          top: vw(188);
          left: vw(1050);
          width: vw(580);
          text-align: left;
          z-index: 5;

          .time {
            font-size: vw(18);
            color: #ccc;
            margin-bottom: vw(12);
          }

          .tit {
            font-size: vw(36);
            font-weight: bold;
            line-height: vw(48);
            color: #fff;
            margin-bottom: vw(20);
          }

          .des {
            font-size: vw(16);
            line-height: vw(30);
            color: #999;
            margin-bottom: vw(20);
          }

          .btn-look {
            display: block;
            width: vw(207);
            height: vw(72);
            background: url("./images/btn-look.png") no-repeat center/contain;
            filter: url('#noise');
          }
        }
      }
    }

    .info-list-box {
      width: vw(985);
      position: absolute;
      right: vw(40);
      top: vw(550);
      z-index: 10;

      .info-list-top {
        width: vw(550);
        margin: 0 auto vw(24);
        display: flex;
        justify-content: space-between;
        align-items: center;

        .list-tab {
          width: vw(222);
          display: flex;
          justify-content: space-between;
          align-items: center;

          .tab {
            color: #fff;
            font-size: vw(18);

            &.active {
              font-weight: bold;
              color: #64f5fb;
            }

            &:hover {
              font-weight: bold;
              color: #64f5fb;
            }
          }
        }

        .icon-more {
          cursor: pointer;
          width: vw(46);
          height: vw(14);
          background: url("./images/icon-more.png") no-repeat center/contain;
          transition: 0.1s;

          &:hover {
            transform: translateY(vw(-6));
          }
        }
      }

      .info-list-center {
        min-height: vw(144);

        .info-list {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          position: relative;

          .info-item {
            width: vw(231);
            height: vw(144);
            background: url("./images/bg-news.png") no-repeat center/cover;
            padding:1px;
            position: relative;
            cursor: pointer;
            margin-right: vw(20);
            -webkit-transition: all 1s ease;
            -moz-transition: all 1s ease;
            -o-transition: all 1s ease;
            transition: all 1s ease;

            &:last-child {
              margin-right: 0;
            }

            &::before {
              content: '';
              width: vw(286);
              height: vw(50);
              background: url("./images/line-1.png") no-repeat center/contain;
              position: absolute;
              top: vw(-14);
              right: vw(-6);
              pointer-events: none;
              z-index: 1;
            }

            &::after {
              content: '';
              width: vw(266);
              height: vw(31);
              background: url("./images/line-2.png") no-repeat center/contain;
              position: absolute;
              bottom: vw(-22);
              right: vw(-34);
              pointer-events: none;
              z-index: 1;
            }

            .cover {
              display: block;
              width: 100%;
              height: 100%;
              object-fit: cover;
              position: relative;
              clip-path: polygon(
                      0 vw(6),
                      vw(6) 0,
                      100% 0,
                      100% calc(100% - vw(6)),
                      calc(100% - vw(6)) 100%,
                      vw(6) 100%,
                      0 100%
              );

              &::before {
                content: "";
                position: absolute;
                height: 450%;
                left: 50%;
                top: 50%;
                width: 150%;
                z-index: -1;
                background: rgba(0, 0, 0, 0.5);
                -moz-transform: translateX(-50%) translateY(-50%) rotate(-25deg);
                -ms-transform: translateX(-50%) translateY(-50%) rotate(-25deg);
                -webkit-transform: translateX(-50%) translateY(-50%) rotate(-25deg);
                transform: translateX(-50%) translateY(-50%) rotate(-25deg);
                -webkit-transition: all 0.75s ease 0s;
                -moz-transition: all 0.75s ease 0s;
                -o-transition: all 0.75s ease 0s;
                transition: all 0.75s ease 0s;
              }

              img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
                mix-blend-mode: multiply;
                image-rendering: auto;
                image-rendering: crisp-edges;
                image-rendering: pixelated;
              }
            }

            .label {
              width: vw(82);
              height: vw(44);
              // position: absolute;
              // left: vw(-3);
              // top: vw(18);

              &.label-video {
                background: url("./images/icon-label1.png") no-repeat center/contain;
              }

              &.label-img {
                background: url("./images/icon-label2.png") no-repeat center/contain;
              }
            }

            .text {
              width: vw(210);
              height: vw(44);
              display: block;
              position: absolute;
              left: vw(-3);
              top: vw(18);
              display: flex;
              align-items: center;
              justify-content: space-between;
              p {
                font-size: vw(18);
                color: #ccc;
                line-height: vw(28);
                display: block;
                height: vw(44);
                display: none;
              }
            }
            h6 {
              font-size: vw(14);
              line-height: vw(22);
              height: vw(22);
              color: #fff;
              margin-bottom: vw(10);
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
              position: absolute;
              width: 100%;
              bottom: vw(-8);
              text-align: center
              // background:rgba($color: #000000, $alpha: .5) ;
            }

            &:hover {
              .cover::before {
                height: 0;
              }
              .text {
                h6, p {
                  color: #64f5fb;
                }
              }
              h6 {
                -webkit-transition: all 0.75s ease 0s;
                -moz-transition: all 0.75s ease 0s;
                -o-transition: all 0.75s ease 0s;
                transition: all 0.75s ease .2s;
                background:rgba($color: #000000, $alpha: .5) ;
              }
            }
          }
        }

      }

      .line {
        display: block;
        width: vw(980);
        height: vw(14);
        background: url("./images/line-3.png") no-repeat center/contain;
        margin: vw(19) auto 0;
      }
    }
  }

  &.part-2-en {
    .main {
      .title {
        width: vw(240);
        height: vw(72);
        background-image: url("./images/en/tit.png");
        right: vw(430);
      }

      .btn-go {

        &.btn-go-one {
          background-image: url("./images/btn-go.png");
        }

        &.btn-show {
          background-size: vw(56640/2) vw(216/2);
          background-image: url("https://cos.enmmo.yepboys.com/sprites/btn-bbs-en-sprites.png");
        }
      }

      .icon-bbs56 {
        background-position: vw(-0/2) vw(0);
      }
      .icon-bbs57 {
        background-position: vw(-590/2) vw(0);
      }
      .icon-bbs58 {
        background-position: vw(-1180/2) vw(0);
      }
      .icon-bbs59 {
        background-position: vw(-1770/2) vw(0);
      }
      .icon-bbs60 {
        background-position: vw(-2360/2) vw(0);
      }
      .icon-bbs61 {
        background-position: vw(-2950/2) vw(0);
      }
      .icon-bbs62 {
        background-position: vw(-3540/2) vw(0);
      }
      .icon-bbs63 {
        background-position: vw(-4130/2) vw(0);
      }
      .icon-bbs64 {
        background-position: vw(-4720/2) vw(0);
      }
      .icon-bbs65 {
        background-position: vw(-5310/2) vw(0);
      }
      .icon-bbs66 {
        background-position: vw(-5900/2) vw(0);
      }
      .icon-bbs67 {
        background-position: vw(-6490/2) vw(0);
      }
      .icon-bbs68 {
        background-position: vw(-7080/2) vw(0);
      }
      .icon-bbs70 {
        background-position: vw(-7670/2) vw(0);
      }
      .icon-bbs69 {
        background-position: vw(-8260/2) vw(0);
      }
      .icon-bbs71 {
        background-position: vw(-8850/2) vw(0);
      }
      .icon-bbs72 {
        background-position: vw(-9440/2) vw(0);
      }
      .icon-bbs73 {
        background-position: vw(-10030/2) vw(0);
      }
      .icon-bbs75 {
        background-position: vw(-10620/2) vw(0);
      }
      .icon-bbs74 {
        background-position: vw(-11210/2) vw(0);
      }
      .icon-bbs78 {
        background-position: vw(-11800/2) vw(0);
      }
      .icon-bbs80 {
        background-position: vw(-12390/2) vw(0);
      }
      .icon-bbs76 {
        background-position: vw(-12980/2) vw(0);
      }
      .icon-bbs77 {
        background-position: vw(-13570/2) vw(0);
      }
      .icon-bbs81 {
        background-position: vw(-14160/2) vw(0);
      }
      .icon-bbs79 {
        background-position: vw(-14750/2) vw(0);
      }
      .icon-bbs82 {
        background-position: vw(-15340/2) vw(0);
      }
      .icon-bbs83 {
        background-position: vw(-15930/2) vw(0);
      }
      .icon-bbs84 {
        background-position: vw(-16520/2) vw(0);
      }
      .icon-bbs85 {
        background-position: vw(-17110/2) vw(0);
      }
      .icon-bbs86 {
        background-position: vw(-17700/2) vw(0);
      }
      .icon-bbs87 {
        background-position: vw(-18290/2) vw(0);
      }
      .icon-bbs88 {
        background-position: vw(-18880/2) vw(0);
      }
      .icon-bbs89 {
        background-position: vw(-19470/2) vw(0);
      }
      .icon-bbs91 {
        background-position: vw(-20060/2) vw(0);
      }
      .icon-bbs90 {
        background-position: vw(-20650/2) vw(0);
      }
      .icon-bbs92 {
        background-position: vw(-21240/2) vw(0);
      }
      .icon-bbs93 {
        background-position: vw(-21830/2) vw(0);
      }
      .icon-bbs94 {
        background-position: vw(-22420/2) vw(0);
      }
      .icon-bbs95 {
        background-position: vw(-23010/2) vw(0);
      }
      .icon-bbs0 {
        background-position: vw(-23600/2) vw(0);
      }
      .icon-bbs1 {
        background-position: vw(-24190/2) vw(0);
      }
      .icon-bbs2 {
        background-position: vw(-24780/2) vw(0);
      }
      .icon-bbs3 {
        background-position: vw(-25370/2) vw(0);
      }
      .icon-bbs4 {
        background-position: vw(-25960/2) vw(0);
      }
      .icon-bbs5 {
        background-position: vw(-26550/2) vw(0);
      }
      .icon-bbs6 {
        background-position: vw(-27140/2) vw(0);
      }
      .icon-bbs7 {
        background-position: vw(-27730/2) vw(0);
      }
      .icon-bbs8 {
        background-position: vw(-28320/2) vw(0);
      }
      .icon-bbs9 {
        background-position: vw(-28910/2) vw(0);
      }
      .icon-bbs11 {
        background-position: vw(-29500/2) vw(0);
      }
      .icon-bbs10 {
        background-position: vw(-30090/2) vw(0);
      }
      .icon-bbs12 {
        background-position: vw(-30680/2) vw(0);
      }
      .icon-bbs13 {
        background-position: vw(-31270/2) vw(0);
      }
      .icon-bbs14 {
        background-position: vw(-31860/2) vw(0);
      }
      .icon-bbs15 {
        background-position: vw(-32450/2) vw(0);
      }
      .icon-bbs17 {
        background-position: vw(-33040/2) vw(0);
      }
      .icon-bbs16 {
        background-position: vw(-33630/2) vw(0);
      }
      .icon-bbs18 {
        background-position: vw(-34220/2) vw(0);
      }
      .icon-bbs19 {
        background-position: vw(-34810/2) vw(0);
      }
      .icon-bbs20 {
        background-position: vw(-35400/2) vw(0);
      }
      .icon-bbs21 {
        background-position: vw(-35990/2) vw(0);
      }
      .icon-bbs22 {
        background-position: vw(-36580/2) vw(0);
      }
      .icon-bbs23 {
        background-position: vw(-37170/2) vw(0);
      }
      .icon-bbs24 {
        background-position: vw(-37760/2) vw(0);
      }
      .icon-bbs25 {
        background-position: vw(-38350/2) vw(0);
      }
      .icon-bbs26 {
        background-position: vw(-38940/2) vw(0);
      }
      .icon-bbs27 {
        background-position: vw(-39530/2) vw(0);
      }
      .icon-bbs28 {
        background-position: vw(-40120/2) vw(0);
      }
      .icon-bbs29 {
        background-position: vw(-40710/2) vw(0);
      }
      .icon-bbs30 {
        background-position: vw(-41300/2) vw(0);
      }
      .icon-bbs31 {
        background-position: vw(-41890/2) vw(0);
      }
      .icon-bbs32 {
        background-position: vw(-42480/2) vw(0);
      }
      .icon-bbs33 {
        background-position: vw(-43070/2) vw(0);
      }
      .icon-bbs34 {
        background-position: vw(-43660/2) vw(0);
      }
      .icon-bbs35 {
        background-position: vw(-44250/2) vw(0);
      }
      .icon-bbs36 {
        background-position: vw(-44840/2) vw(0);
      }
      .icon-bbs37 {
        background-position: vw(-45430/2) vw(0);
      }
      .icon-bbs38 {
        background-position: vw(-46020/2) vw(0);
      }
      .icon-bbs39 {
        background-position: vw(-46610/2) vw(0);
      }
      .icon-bbs40 {
        background-position: vw(-47200/2) vw(0);
      }
      .icon-bbs41 {
        background-position: vw(-47790/2) vw(0);
      }
      .icon-bbs42 {
        background-position: vw(-48380/2) vw(0);
      }
      .icon-bbs43 {
        background-position: vw(-48970/2) vw(0);
      }
      .icon-bbs44 {
        background-position: vw(-49560/2) vw(0);
      }
      .icon-bbs45 {
        background-position: vw(-50150/2) vw(0);
      }
      .icon-bbs46 {
        background-position: vw(-50740/2) vw(0);
      }
      .icon-bbs47 {
        background-position: vw(-51330/2) vw(0);
      }
      .icon-bbs48 {
        background-position: vw(-51920/2) vw(0);
      }
      .icon-bbs49 {
        background-position: vw(-52510/2) vw(0);
      }
      .icon-bbs50 {
        background-position: vw(-53100/2) vw(0);
      }
      .icon-bbs51 {
        background-position: vw(-53690/2) vw(0);
      }
      .icon-bbs52 {
        background-position: vw(-54280/2) vw(0);
      }
      .icon-bbs53 {
        background-position: vw(-54870/2) vw(0);
      }
      .icon-bbs54 {
        background-position: vw(-55460/2) vw(0);
      }
      .icon-bbs55 {
        background-position: vw(-56050/2) vw(0);
      }


      .btn-look {
        background-image: url("./images/en/btn-look.png")!important;
      }

      .info-list-box {
        .info-list-top {
          .list-tab {
            width: vw(400);
          }
        }

        .info-list-center {

          .info-list {

            .info-item {

              .label {
                &.label-video {
                  background-image: url("./images/en/icon-label1.png");
                }

                &.label-img {
                  width: vw(137);
                  background: url("./images/en/icon-label2.png") no-repeat center/100%;
                }
              }

            }
          }
        }

      }
    }
  }
}
</style>
<style lang="scss">

.list-pagination {
  display: block;
  width: vw(8);
  height: vw(8);
  background: url("./images/icon-pagination1.png") no-repeat center/contain;
  opacity: 1;
  margin: 0 auto vw(9);
}

.list-pagination-active {
  width: vw(16);
  height: vw(12);
  background: url("./images/icon-pagination2.png") no-repeat center/contain;
  opacity: 1;
}
</style>
