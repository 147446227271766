import request from '@/utils/request_xj'
// 获取短信验证码
export function getPhoneCode(data) {
    return request({
        url: '/api/official_auth/code',
        method: 'post',
        data
    })
}

// 获取邮箱验证码
export function getEmailCode(data) {
    return request({
        url: '/api/official_auth/MailCode',
        method: 'post',
        data
    })
}

// 手机号注册
export function register(data) {
    return request({
        url: '/api/official_auth/register',
        method: 'post',
        data
    })
}

// 邮箱注册
export function registerEmail(data) {
    return request({
        url: '/api/official_auth/mail_register',
        method: 'post',
        data
    })
}

// 实名认证
export function realName(data) {
    return request({
        url: '/api/official_auth/AuthName',
        method: 'post',
        data
    })
}

// 登录
export function login(data) {
    return request({
        url: '/api/official_auth/login',
        method: 'post',
        data
    })
}
// 获取用户信息
export function getUserinfo(data) {
    return request({
        url: '/api/official_auth/user_info',
        method: 'post',
        data
    })
}
  