<template>
  <div :class="['part', 'part-kv', lang === 'en' && 'part-kv-en']">
    <video src="https://cos.enmmo.yepboys.com/video/bg-kv.mp4" class="video-bg" loop autoplay muted/>
    <div class="kv-main">
      <a href="javascript:;" class="icon-play"  @click="handleOpenVideoModal" />
      <!-- <a href="javascript:;" class="icon-play"/> -->

      <div class="slogan" />
      <a href="javascript:;" :class="['btn-order', isOrder ? 'btn-order-end' : isAllLoaded ? `btn-show btn-order${ainIndex}` : 'btn-order-one']" @click="handleOpenRegisterModal" />
    </div>
    <div class="right-bar">
      <div class="icon-prize">
        <span>{{lang === 'en' ? 'Pre-registration Rewards' : '预约奖励'}}</span>
        <div class="prize-modal">
          <img src="./images/award1.png" alt="">
          <p>*The final effect of skin reward is mainly based on the actual effect*</p>
          <img class="text" src="./images/text.png" alt="">
<!--          <img :src="lang === 'en' ? require('./images/en/img-prize.png') : require('./images/img-prize.png')" alt="prize" />-->
        </div>
      </div>
      <div class="channel-list">
        <a v-for="(item, index) in channelList" :key="index" @mouseover="showqrcode(item)"  @mouseleave="hideqrcode(item)" @click="linkUrl(item)" href="javascript:;" :class="`icon-${item.icon}`" />
        <img v-if="qqqr" class="qq-qrcode" src="./images/qq-qrcode.png" alt="">
        <img v-if="wxqr" class="wx-qrcode" src="./images/wx-qrcode.png" alt="">
      </div>
    </div>
  </div>
</template>
<script>
import Tracker from "@/utils/tracker";
export default {
  name: 'KV',
  props: {
    isOrder: {
      type: Boolean,
      default: false
    },
    lang: {
      type: String,
      default: 'zh-CN'
    }
  },
  data() {
    return {
      channelList: [],
      isAllLoaded: false,
      ainIndex: 0,
      videoLink: 'cos.chmmo.yepboys.com/video/kv-vid.mp4',
      qqqr:false,
      wxqr:false,
    }
  },
  created() {
    this.channelList = this.$t('channelList')
  },
  watch: {
    lang: function() {
      this.channelList = this.$t('channelList')
      this.ainIndex = 0
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.preloadImages(52, this.handleImageLoad)
    })
  },
  methods: {
    // 打开注册弹窗
    handleOpenRegisterModal() {
      Tracker.openRegisterModal_view()
      this.$emit('openRegisterModal')
    },
    // 打开视频弹窗
    handleOpenVideoModal() {
      Tracker.handleOpenVideoModal_view()
      this.$emit('openVideoModal', this.videoLink)
    },
    handleCreateFrameAnimation(length) {
      let lastTime = 0; // 上次执行动画的时间戳

      const animate = (currentTime) => {
        const deltaTime = currentTime - lastTime; // 计算时间差

        // 如果时间差大于某个阈值（例如16ms，即60帧每秒），执行动画
        if (deltaTime > 32) {
          lastTime = currentTime; // 更新上次执行动画的时间戳
          if (this.ainIndex >= length) {
            this.ainIndex = 0;
          } else {
            this.ainIndex++;
          }
        }

        // 通过 requestAnimationFrame 方法递归调用自身
        requestAnimationFrame(animate);
      };

      // 第一次调用动画函数
      animate();
    },
    preloadImages(length, callback) {
      const url = this.lang === 'en' ? 'https://cos.enmmo.yepboys.com/sprites/btn-order-en-sprites.png' : 'https://cos.enmmo.yepboys.com/sprites/btn-order-cn-sprites.png';
      const img = new Image();
      img.src = url;
      img.onload = () => {
        callback(length)
      };

    },
    handleImageLoad(length) {
      this.isAllLoaded = true;
      this.handleCreateFrameAnimation(length)
    },

    linkUrl(item){
      if(item.icon == 'qq' ||  item.icon == 'wx' ){
        return
      }else{
        Tracker.linkUrl_view()
        window.open(item.url)  
      }
    },
    showqrcode(item){
      if(item.icon == 'qq'){
        console.log(item)
        this.qqqr = true
      }else if(item.icon == 'wx'){
        this.wxqr = true
      }
    },
    hideqrcode(item){
      if(item.icon == 'qq'){
        this.qqqr = false
      }else if(item.icon == 'wx'){
        this.wxqr = false
      }
    },

  }
}
</script>

<style scoped lang="scss">
.part-kv {
  background: url("https://cos.enmmo.yepboys.com/images/pc-bg-part1.jpg") no-repeat center/cover;

  .video-bg {
    width: 120%;
    height: 120%;
    object-fit: cover;
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .kv-main {
    width: vw(922);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-46%);

    .icon-play {
      width: vw(234);
      height: vw(125);
      background: url("@/assets/images/icon-play.png") no-repeat center/contain;
      display: block;
      margin: 0 auto;

      &:hover {
        background: url("@/assets/images/icon-play2.png") no-repeat center/contain;
      }
    }

    .slogan {
      width: vw(1130);
      height: vw(198);
      background: url("https://cos.enmmo.yepboys.com/images/pc-slogan-cn.png") no-repeat center/contain;
      margin: 0 auto 0;
      transform: translateX(vw(-102));
    }

    .btn-order {
      background-repeat: no-repeat;
      display: block;
      margin: 0 auto 0;

      &.btn-order-end {
        width: vw(552);
        height: vw(143);
        background-size: contain;
        background-position: center;
        background-image: url("./images/btn-order-end.png");
        pointer-events: none;
      }

      &.btn-order-one {
        width: vw(606);
        height: vw(206);
        background-size: contain;
        background-position: center;
        background-image: url("./images/btn-order.png");
      }

      &.btn-show {
        width: vw(606);
        height: vw(206);
        background-size: vw(64236/2) vw(412/2);
        background-image: url("https://cos.enmmo.yepboys.com/sprites/btn-order-cn-sprites.png");
      }

      // 点击按钮动画
      &:active {
        transform: scale(0.9);
        transition: transform 0.1s;
      }
    }

    .btn-order21 {
      background-position: vw(0) vw(0);
    }
    .btn-order22 {
      background-position: vw(-1212/2) vw(0);
    }
    .btn-order23 {
      background-position: vw(-2424/2) vw(0);
    }
    .btn-order24 {
      background-position: vw(-3636/2) vw(0);
    }
    .btn-order25 {
      background-position: vw(-4848/2) vw(0);
    }
    .btn-order26 {
      background-position: vw(-6060/2) vw(0);
    }
    .btn-order27 {
      background-position: vw(-7272/2) vw(0);
    }
    .btn-order28 {
      background-position: vw(-8484/2) vw(0);
    }
    .btn-order29 {
      background-position: vw(-9696/2) vw(0);
    }
    .btn-order30 {
      background-position: vw(-10908/2) vw(0);
    }
    .btn-order31 {
      background-position: vw(-12120/2) vw(0);
    }
    .btn-order32 {
      background-position: vw(-13332/2) vw(0);
    }
    .btn-order33 {
      background-position: vw(-14544/2) vw(0);
    }
    .btn-order34 {
      background-position: vw(-15756/2) vw(0);
    }
    .btn-order0 {
      background-position: vw(-16968/2) vw(0);
    }
    .btn-order35 {
      background-position: vw(-18180/2) vw(0);
    }
    .btn-order36 {
      background-position: vw(-19392/2) vw(0);
    }
    .btn-order37 {
      background-position: vw(-20604/2) vw(0);
    }
    .btn-order38 {
      background-position: vw(-21816/2) vw(0);
    }
    .btn-order39 {
      background-position: vw(-23028/2) vw(0);
    }
    .btn-order41 {
      background-position: vw(-24240/2) vw(0);
    }
    .btn-order1 {
      background-position: vw(-25452/2) vw(0);
    }
    .btn-order40 {
      background-position: vw(-26664/2) vw(0);
    }
    .btn-order42 {
      background-position: vw(-27876/2) vw(0);
    }
    .btn-order43 {
      background-position: vw(-29088/2) vw(0);
    }
    .btn-order44 {
      background-position: vw(-30300/2) vw(0);
    }
    .btn-order45 {
      background-position: vw(-31512/2) vw(0);
    }
    .btn-order46 {
      background-position: vw(-32724/2) vw(0);
    }
    .btn-order47 {
      background-position: vw(-33936/2) vw(0);
    }
    .btn-order48 {
      background-position: vw(-35148/2) vw(0);
    }
    .btn-order49 {
      background-position: vw(-36360/2) vw(0);
    }
    .btn-order50 {
      background-position: vw(-37572/2) vw(0);
    }
    .btn-order52 {
      background-position: vw(-38784/2) vw(0);
    }
    .btn-order51 {
      background-position: vw(-39996/2) vw(0);
    }
    .btn-order3 {
      background-position: vw(-41208/2) vw(0);
    }
    .btn-order2 {
      background-position: vw(-42420/2) vw(0);
    }
    .btn-order5 {
      background-position: vw(-43632/2) vw(0);
    }
    .btn-order4 {
      background-position: vw(-44844/2) vw(0);
    }
    .btn-order6 {
      background-position: vw(-46056/2) vw(0);
    }
    .btn-order7 {
      background-position: vw(-47268/2) vw(0);
    }
    .btn-order8 {
      background-position: vw(-48480/2) vw(0);
    }
    .btn-order9 {
      background-position: vw(-49692/2) vw(0);
    }
    .btn-order10 {
      background-position: vw(-50904/2) vw(0);
    }
    .btn-order11 {
      background-position: vw(-52116/2) vw(0);
    }
    .btn-order13 {
      background-position: vw(-53328/2) vw(0);
    }
    .btn-order12 {
      background-position: vw(-54540/2) vw(0);
    }
    .btn-order14 {
      background-position: vw(-55752/2) vw(0);
    }
    .btn-order15 {
      background-position: vw(-56964/2) vw(0);
    }
    .btn-order16 {
      background-position: vw(-58176/2) vw(0);
    }
    .btn-order17 {
      background-position: vw(-59388/2) vw(0);
    }
    .btn-order18 {
      background-position: vw(-60600/2) vw(0);
    }
    .btn-order19 {
      background-position: vw(-61812/2) vw(0);
    }
    .btn-order20 {
      background-position: vw(-63024/2) vw(0);
    }



  }

  .right-bar {
    width: vw(135);
    position: absolute;
    right: vw(12);
    bottom: vw(90);

    .icon-prize {
      width: vw(110);
      height: vw(110);
      margin: 0 auto 0;
      position: relative;
      cursor: pointer;

      &::before {
        content: '';
        width: vw(110);
        height: vw(110);
        background: url("./images/icon-prize.png") no-repeat center/contain;
        position: absolute;
        top: 0;
        left: 50%;
        margin-left: vw(-55);
        animation: showRotate 6s linear infinite;
      }

      @keyframes showRotate {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(720deg);
        }
      }

      span {
        width: vw(45);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-49%, -50%);
        font-size: vw(16);
        font-weight: bold;
        text-align: center;
        color: #fff;
      }

      .prize-modal {
        display: none;
        width: vw(205);
        height: vw(686);
        background: url("./images/bg-prize.png") no-repeat center/contain;
        position: absolute;
        left: vw(-180);
        top: 50%;
        transform: translateY(-55%);
        padding-top: vw(120);

        p{
          width: vw(140);
          font-size: vw(11);
          color: #999;
          text-align: center;
          margin: 0 auto;
        }
        img {
          display: block;
          margin: 0 auto;
          width: vw(140);
          object-fit: contain;
        }
        .text{
          width: vw(143);
          margin:0  auto;
          display: block;
          margin-top: vw(65);
        }
      }

      span:hover {
        + .prize-modal {
          display: block;
        }
      }
    }

    .channel-list {
      width: vw(31);
      margin: vw(20) auto 0;
      position: relative;
      z-index: 2;
      .qq-qrcode{
        width: vw(80);
        height: vw(80);
        position: absolute;
        left: vw(-85);
        top: vw(10);
      }
      .wx-qrcode{
        width: vw(80);
        height: vw(80);
        position: absolute;
        left: vw(-85);
        top: vw(-30);
      }

      a {
        display: block;
        margin: vw(12) auto;
      }

      .icon-wx {
        width: vw(31);
        height: vw(25);
        background: url("./images/icon-wx.png") no-repeat center/contain;

        &:hover {
          background: url("./images/icon-wx-active.png") no-repeat center/contain;
        }
      }

      .icon-qq {
        width: vw(23);
        height: vw(27);
        background: url("./images/icon-qq.png") no-repeat center/contain;

        &:hover {
          background: url("./images/icon-qq-active.png") no-repeat center/contain;
        }

      }

      .icon-wb {
        width: vw(31);
        height: vw(26);
        background: url("./images/icon-wb.png") no-repeat center/contain;

        &:hover {
          background: url("./images/icon-wb-active.png") no-repeat center/contain;
        }
      }

      .icon-dy {
        width: vw(26);
        height: vw(32);
        background: url("./images/icon-dy.png") no-repeat center/contain;

        &:hover {
          background: url("./images/icon-dy-active.png") no-repeat center/contain;
        }
      }

      .icon-ds {
        width: vw(23);
        height: vw(27);
        background: url("./images/en/icon-discord.png") no-repeat center/contain;

        &:hover {
          background: url("./images/en/icon-discord-active.png") no-repeat center/contain;
        }
      }

      .icon-x {
        width: vw(23);
        height: vw(27);
        background: url("./images/en/icon-twitter.png") no-repeat center/contain;

        &:hover {
          background: url("./images/en/icon-twitter-active.png") no-repeat center/contain;
        }
      }

      .icon-yt {
        width: vw(23);
        height: vw(27);
        background: url("./images/en/icon-youtube.png") no-repeat center/contain;

        &:hover {
          background: url("./images/en/icon-youtube-active.png") no-repeat center/contain;
        }
      }

      .icon-tt {
        width: vw(26);
        height: vw(32);
        background: url("./images/en/icon-tiktok.png") no-repeat center/contain;

        &:hover {
          background: url("./images/en/icon-tiktok-active.png") no-repeat center/contain;
        }
      }
    }
  }

  &.part-kv-en {
    .kv-main {
      .slogan {
        width: vw(1224);
        height: vw(233);
        background: url("https://cos.enmmo.yepboys.com/images/pc-slogan-en.png") no-repeat center/contain;
      }

      .btn-order {
        &.btn-order-end {
          background-image: url("./images/en/btn-order-end.png");
        }

        &.btn-order-one {
          background-image: url("./images/en/btn-order.png");
        }

        &.btn-show {
          background-size: vw(64236/2) vw(412/2);
          background-image: url("https://cos.enmmo.yepboys.com/sprites/btn-order-en-sprites.png");
        }
      }

      .btn-order0 {
        background-position: vw(-0/2) vw(0);
      }
      .btn-order1 {
        background-position: vw(-1212/2) vw(0);
      }
      .btn-order21 {
        background-position: vw(-2424/2) vw(0);
      }
      .btn-order22 {
        background-position: vw(-3636/2) vw(0);
      }
      .btn-order23 {
        background-position: vw(-4848/2) vw(0);
      }
      .btn-order37 {
        background-position: vw(-6060/2) vw(0);
      }
      .btn-order36 {
        background-position: vw(-7272/2) vw(0);
      }
      .btn-order39 {
        background-position: vw(-8484/2) vw(0);
      }
      .btn-order24 {
        background-position: vw(-9696/2) vw(0);
      }
      .btn-order38 {
        background-position: vw(-10908/2) vw(0);
      }
      .btn-order40 {
        background-position: vw(-12120/2) vw(0);
      }
      .btn-order25 {
        background-position: vw(-13332/2) vw(0);
      }
      .btn-order26 {
        background-position: vw(-14544/2) vw(0);
      }
      .btn-order27 {
        background-position: vw(-15756/2) vw(0);
      }
      .btn-order28 {
        background-position: vw(-16968/2) vw(0);
      }
      .btn-order32 {
        background-position: vw(-18180/2) vw(0);
      }
      .btn-order29 {
        background-position: vw(-19392/2) vw(0);
      }
      .btn-order31 {
        background-position: vw(-20604/2) vw(0);
      }
      .btn-order35 {
        background-position: vw(-21816/2) vw(0);
      }
      .btn-order33 {
        background-position: vw(-23028/2) vw(0);
      }
      .btn-order34 {
        background-position: vw(-24240/2) vw(0);
      }
      .btn-order30 {
        background-position: vw(-25452/2) vw(0);
      }
      .btn-order41 {
        background-position: vw(-26664/2) vw(0);
      }
      .btn-order43 {
        background-position: vw(-27876/2) vw(0);
      }
      .btn-order52 {
        background-position: vw(-29088/2) vw(0);
      }
      .btn-order42 {
        background-position: vw(-30300/2) vw(0);
      }
      .btn-order2 {
        background-position: vw(-31512/2) vw(0);
      }
      .btn-order44 {
        background-position: vw(-32724/2) vw(0);
      }
      .btn-order3 {
        background-position: vw(-33936/2) vw(0);
      }
      .btn-order4 {
        background-position: vw(-35148/2) vw(0);
      }
      .btn-order45 {
        background-position: vw(-36360/2) vw(0);
      }
      .btn-order48 {
        background-position: vw(-37572/2) vw(0);
      }
      .btn-order47 {
        background-position: vw(-38784/2) vw(0);
      }
      .btn-order46 {
        background-position: vw(-39996/2) vw(0);
      }
      .btn-order50 {
        background-position: vw(-41208/2) vw(0);
      }
      .btn-order49 {
        background-position: vw(-42420/2) vw(0);
      }
      .btn-order51 {
        background-position: vw(-43632/2) vw(0);
      }
      .btn-order7 {
        background-position: vw(-44844/2) vw(0);
      }
      .btn-order8 {
        background-position: vw(-46056/2) vw(0);
      }
      .btn-order10 {
        background-position: vw(-47268/2) vw(0);
      }
      .btn-order13 {
        background-position: vw(-48480/2) vw(0);
      }
      .btn-order6 {
        background-position: vw(-49692/2) vw(0);
      }
      .btn-order11 {
        background-position: vw(-50904/2) vw(0);
      }
      .btn-order9 {
        background-position: vw(-52116/2) vw(0);
      }
      .btn-order14 {
        background-position: vw(-53328/2) vw(0);
      }
      .btn-order16 {
        background-position: vw(-54540/2) vw(0);
      }
      .btn-order17 {
        background-position: vw(-55752/2) vw(0);
      }
      .btn-order15 {
        background-position: vw(-56964/2) vw(0);
      }
      .btn-order18 {
        background-position: vw(-58176/2) vw(0);
      }
      .btn-order19 {
        background-position: vw(-59388/2) vw(0);
      }
      .btn-order12 {
        background-position: vw(-60600/2) vw(0);
      }
      .btn-order5 {
        background-position: vw(-61812/2) vw(0);
      }
      .btn-order20 {
        background-position: vw(-63024/2) vw(0);
      }



    }

    .right-bar {
      .icon-prize {
        span {
          width: vw(120);
          text-align: center;
        }
      }

      .prize-modal {
        background: url("./images/en/bg-prize.png") no-repeat center/contain;
      }
    }
  }
}

</style>
