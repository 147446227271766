// 全局modal方法

//打开弹窗
export function openModal() {
    // 找到dom
    let bg= document.querySelector('.modal-bg');
    // 找到modal
    let modal = document.querySelector('.modal');
    if (bg) {
        //阻止背景滚动 包含鼠标滚轮和触摸滚动
        bg.addEventListener('mousewheel', function (e) {
            e.preventDefault();
            e.stopPropagation();
        });
        modal.addEventListener('mousewheel', function (e) {
            e.stopPropagation();
        });
    }
}



// 关闭弹窗

export function closeModal() {
    // 找到dom
    let bg= document.querySelector('.modal-bg');
    // 找到modal
    let modal = document.querySelector('.modal');
    if (bg) {
        // 阻止背景滚动 包含鼠标滚轮和触摸滚动
        bg.removeEventListener('mousewheel', function (e) {
            e.preventDefault();
            e.stopPropagation();
        });

        modal.removeEventListener('mousewheel', function (e) {
            e.stopPropagation();
        });
    }
}
