import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        component: () => import('@/views/index'),
    },
    {
        path: '/list',
        component: () => import('@/views/list/index'),
    },
    {
        path: '/details',
        component: () => import('@/views/details/index'),
    },
    {
        path: '/race',
        component: () => import('@/views/race/index'),
    },
    {
        path: '/ship',
        component: () => import('@/views/ship/index'),
    },
    // 404处理
    {
        path: '*',
        redirect: '/'
    },
];

const router = new VueRouter({
    mode: 'hash', // 设置为 history 模式，去除 URL 中的 # 符号
    routes
});

export default router;
