<template>
  <div :class="['modal', 'register-modal', lang === 'en' && 'register-modal-en']">
    <a href="javascript:;" class="icon-close" @click="handleCloseModal"/>
    <div class="modal-content">
      <input type="text" class="input input-name" :placeholder="$t('modal.userPlaceholder')" v-model="loginData.user_name">
      <input v-if="lang !== 'en'" class="input input-phone" type="text"
             v-model="loginData.phone" :placeholder="$t('modal.phonePlaceholder')"/>
      <input class="input input-mail" type="text" v-model="loginData.mail_box"
             :placeholder="$t('modal.mailPlaceholder')"/>
      <div class="code">
        <input class="input input-code" type="text" v-model="loginData.code" :placeholder="$t('modal.codePlaceholder')"/>
        <a href="javascript:;" :class="['btn-getCode', getCountdown !== 60 && 'disabled']"
           @click="handleGetCode">{{ getCountdown === 60 ? $t('modal.getCode') : `${getCountdown}S` }}</a>
      </div>
      <div class="protocol">
        <span class="checkbox" @click="handleConfirmProtocol">
          <i v-if="isConfirmProtocol" class="icon-check" />
        </span>
        <p v-if="lang === 'zh-CN'">我已经阅读并同意遵守相关<a href="javascript:;" class="link" @click="handleProtocol(4)">服务条款</a>和<a href="javascript:;" class="link" @click="handleProtocol(5)">隐私协议</a></p>
        <p v-if="lang === 'en'">I have read and agree to the <a href="javascript:;" class="link" @click="handleProtocol(4)"> terms and conditions</a> and <a href="javascript:;" class="link" @click="handleProtocol(5)">Privacy Policy</a></p>
      </div>
      <div class="protocol">
        <span class="checkbox" @click="handleReceiveInfo">
          <i v-if="loginData.is_receive === 1" class="icon-check" />
        </span>
        <p>{{$t('modal.receiveGameNews')}}</p>
      </div>
      <a href="javascript:;" class="btn-next" @click="handleNext"/>
      <div class="login-box">
        <p v-if="lang === 'zh-CN'">已有账号？<a href="javascript:;" @click="handleOpenLoginModal">立即登录</a></p>
        <p v-if="lang === 'en'">I've Registered, <a href="javascript:;" @click="handleOpenLoginModal">Go to Log In</a></p>
      </div>
    </div>
  </div>
</template>


<script>
import {isEmail, isPhone, setCookie} from "@/utils/utils";
import {getEmailCode, getPhoneCode, register, registerEmail} from "@/api/main";
import Tracker from "@/utils/tracker";
export default {
  name: 'RegisterModal',
  props: {
    isConfirmProtocol: {
      type: Boolean,
      default: false
    },
    lang: {
      type: String,
      default: 'zh-CN'
    }
  },
  data() {
    return {
      getCountdown: 60,
      loginData: {
        user_name: '',
        phone: '',
        mail_box: '',
        code: '',
        is_receive: 0
      }
    }
  },
  mounted() {
    // 判断localeStorage中是否有倒计时的值
    if (localStorage.getItem('countdown')) {
      this.getCountdown = localStorage.getItem('countdown');
      this.countdown()
    }
  },
  methods: {
    handleCloseModal() {
      this.$emit('close', 'showRegisterModal');
    },
    // 点击获取验证码
    handleGetCode() {
      if (this.getCountdown !== 60) {
        return false;
      }
      Tracker.handleGetCode_view()
      if (this.lang === 'en') {
        // 判断邮箱是否已经填写并且是正确的邮箱
        if (!isEmail(this.loginData.mail_box)) {
          this.$toast.error(this.$t('modal.mailError'));
          return false;
        } else {
          this.getMailCode();
          this.countdown();
        }

      } else {
        // 判断手机号码是否已经填写并且是正确的手机号码
        if (!isPhone(this.loginData.phone)) {
          this.$toast.error(this.$t('modal.phoneError'));
          return false;
        } else {
          this.getPhoneCode();
          this.countdown();
        }
      }
    },
    // 倒计时
    countdown() {
      if (this.getCountdown === 0) {
        this.getCountdown = 60;
        localStorage.removeItem('countdown');
        return false;
      } else {
        this.getCountdown--;
        localStorage.setItem('countdown', this.getCountdown);
        setTimeout(() => {
          this.countdown();
        }, 1000);
      }
    },
    // 获取手机号码验证码
    getPhoneCode() {
      const params = {
        phone: this.loginData.phone.toString()
      };
      Tracker.getPhoneCode_view()
      getPhoneCode(params).then(response => {
        // console.log(response);
      });
    },
    // 获取邮箱验证码
    getMailCode() {
      const params = {
        mail: this.loginData.mail_box
      };
      Tracker.getMailCode_view()
      getEmailCode(params).then(response => {
        // console.log(response);
      });
    },
    // 点击同意协议
    handleConfirmProtocol() {
      Tracker.handleConfirmProtocol_view()
      this.$emit('toggleProtocol')
    },
    // 点击是否接收游戏资讯
    handleReceiveInfo() {
      Tracker.handleReceiveInfo_view()
      this.loginData.is_receive = this.loginData.is_receive === 1 ? 0 : 1;
    },
    // 点击下一步
    handleNext() {
      if (!this.loginData.user_name) {
        this.$toast.error(this.$t('modal.userPlaceholder'));
        return false;
      }
      if (this.lang === 'en') {
        if (!isEmail(this.loginData.mail_box)) {
          this.$toast.error(this.$t('modal.mailError'));
          return false;
        }
      } else {
        if (!isPhone(this.loginData.phone)) {
          this.$toast.error(this.$t('modal.phoneError'));
          return false;
        }
        if (!isEmail(this.loginData.mail_box)) {
          this.$toast.error(this.$t('modal.mailError'));
          return false;
        }
      }
      if (!this.loginData.code) {
        this.$toast.error(this.$t('modal.codePlaceholder'));
        return false;
      }
      if (!this.isConfirmProtocol) {
        this.$emit('openProtocol', 'register-modal');
        return false;
      }
      Tracker.login_view()

      if (this.lang === 'en') {
        registerEmail(this.loginData).then(response => {
          if (response.code === 0) {
            this.handleCloseModal();
            this.$toast.success("Register successfully!");
            this.$emit('openLogin');
            localStorage.removeItem('countdown');
            setCookie('token', response.data.auth.token, response.data.auth.expire);
            setCookie('id', response.data.user.id, response.data.auth.expire);
            setCookie('name', response.data.user.name, response.data.auth.expire);
          }
        });
      } else {
        register(this.loginData).then(response => {
          if (response.code === 0) {
            this.handleCloseModal();
            this.$toast.success("注册成功!");
            // 如果是中文环境则跳转到实名认证页面
            this.$emit('openRealName');
            localStorage.removeItem('countdown');
            // 把接口返回的token和过期时间存储到cookie中
            setCookie('token', response.data.auth.token, response.data.auth.expire);
            setCookie('id', response.data.user.id, response.data.auth.expire);
            setCookie('name', response.data.user.name, response.data.auth.expire);
          }
        });
      }
    },
    // 点击立即登录
    handleOpenLoginModal() {
      this.handleCloseModal();
      this.$emit('openLogin');
      Tracker.handleOpenLoginModal_view()
    },
    // 点击协议
    handleProtocol(type) {
      // 跳转到协议页面
      Tracker.handleProtocol_view()
      // const url = this.$router.resolve({path:'/details', query: {type: type}})
      // window.open(url.href, '_blank')
      this.$router.push({ path: '/details', query: { type: type } })
    }
  }
}
</script>

<style scoped lang="scss">
.register-modal {
  width: vw(611);
  height: vw(543);
  background: url("./images/bg.png") no-repeat center/cover;
  padding: vw(113) vw(67) vw(22) vw(66);

  .icon-close {
    top: vw(41);
    right: 0;
  }

  .input {
    width: vw(380);
    margin: 0 auto vw(9);

    &.input-code {
      width: vw(220);
      margin: 0;
    }
  }

  .code {
    width: vw(380);
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .btn-getCode {
      width: vw(150);
      height: vw(44);
      border: vw(1) solid #64f5fb;
      background: #418c95;
      font-size: vw(16);
      text-align: center;
      line-height: vw(42);
      font-weight: bold;
      color: #fff;

      &.disabled {
        filter: grayscale(1);
        pointer-events: none;
      }
    }
  }

  .protocol {
    width: vw(380);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: vw(15) auto;

    .checkbox {
      width: vw(20);
      height: vw(20);
      border: vw(1) solid #64f5fb;
      border-radius: 50%;
      margin-right: vw(6);
      display: flex;
      justify-content: center;
      align-items: center;

      .icon-check {
        width: vw(11);
        height: vw(8);
        background: url("./images/icon-right.png") no-repeat center/contain;
      }
    }

    p {
      font-size: vw(16);
      color: #fff;

      a {
        color: #62eff6;
      }
    }
  }

  .btn-next {
    display: block;
    width: vw(167);
    height: vw(76);
    background: url("./images/btn-next.png") no-repeat center/contain;
    margin: vw(10) auto;
  }

  .login-box {
    text-align: center;
    font-size: vw(16);
    color: #fff;

    a {
      color: #62eff6;
      text-decoration: underline;
    }
  }

  &.register-modal-en {
    padding-top: vw(120);
    background-image: url("./images/en/bg.png");

    .btn-next {
      background-image: url("./images/en/btn-next.png");
    }

    .input {
      width: vw(420);
      margin: 0 auto vw(20);

      &::placeholder {
        color: #fff;
        opacity: 0.6;
        font-size: vw(14);
      }

      &.input-code {
        width: vw(240);
        margin: 0;
      }
    }

    .code {
      width: vw(420);


      .btn-getCode {
        width: vw(160);
        font-size: vw(14);
      }
    }

    .protocol {
      margin: vw(12) auto;
      p {
        width: 95%;
        font-size: vw(14);
      }
    }

    .btn-next {
      margin: 0 auto vw(5);
    }
  }
}
</style>
