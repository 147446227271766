import request from '@/utils/request'
// 获取banner列表
export function getBannerList(params) {
  return request({
    url: '/api/brief/news',
    method: 'get',
    params
  })
}

export function getconceptstation(params) {
  return request({
    url: '/api/concept_station/list',
    method: 'get',
    params
  })
}

// 获取资讯列表
export function getInfoList(params) {
  return request({
    url: '/api/article/news',
    method: 'get',
    params
  })
}

// 获取新闻列表
export function getNewsList(params) {
  return request({
    url: '/api/article/list',
    method: 'get',
    params
  })
}

// 获取新闻详情
export function getNewsDetail(params) {
  return request({
    url: '/api/article/detail',
    method: 'get',
    params
  })
}

// 获取服务协议和隐私政策
export function getAgreement(params) {
  return request({
    url: '/api/article/content',
    method: 'get',
    params
  })
}

