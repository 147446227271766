<template>
  <div class="tips-down">
    <p>SCROLL</p>
    <span class="icon-arr" />
    <div class="line-box">
      <span class="line line-left" />
      <i class="icon-block" />
      <span class="line line-right" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tips',
  props: {

  },
  data() {
    return {

    }
  }
}
</script>

<style scoped lang="scss">

.tips-down {
  width: vw(1840);
  position: fixed;
  left: 50%;
  margin-left: vw(-920);
  bottom: vw(5);
  z-index: 100;

  p {
    font-size: vw(16);
    text-align: center;
    color: #63f6fb;
    margin: 0 auto vw(12);
  }

  .icon-arr {
    display: block;
    width: vw(20);
    height: vw(12);
    background: url("./images/icon-arr.png") no-repeat center/contain;
    margin: 0 auto vw(6);
    animation: changeOpacity linear infinite 1s;
    animation-delay: 0.3s;
  }

  .line-box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .line {
      width: vw(910);
      height: vw(1);
      background: #515258;
      position: relative;

      &::before {
        content: '';
        width: vw(80);
        height: vw(1);
        background: #837e85;
        position: absolute;
        top: 0;
        animation: changeWidth ease-in-out 2s infinite;
      }

      &.line-left {
        &::before {
          right: 0;
        }
      }

      &.line-right {
        &::before {
          left: 0;
        }
      }
    }

    .icon-block {
      width: vw(5);
      height: vw(5);
      background: #63f6fb;
      animation: changeOpacity linear infinite 1s;
    }
  }
}

@keyframes changeOpacity {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0.75;
  }
  50% {
    opacity: 0.5;
  }
  75% {
    opacity: 0.75;
  }
  100% {
    opacity: 1;
  }
}

@keyframes changeWidth {
  0% {
    width: 0;
  }
  50% {
    width: vw(200);
  }
  100% {
    width: 0;
  }
}

</style>
