<template>
  <div :class="['header', showBG && 'showBG']" >
    <a href="javascript:;"  class="logo"/>
    <audio src="https://cos.chmmo.yepboys.com/video/bgm.mp3" id="audio" loop type="audio/mpeg" ></audio>
    <a href="javascript:;" :class="['audio',  internalAudio?'yes':'no']" @click="playaudio(!internalAudio)"></a>
    <div :class="['nav', lang === 'en' ? 'nav-en' : 'nav-cn']">
        <a v-for="(nav, index) in navList" :key=index @click="handleClickNav(index)" href="javascript:;" :class="['nav-item', index === active && 'active']">{{nav.name}}</a>
        <a href="javascript:;" class="language" @click="changeLanguage">{{lang === 'en' ? 'EN' : 'CN'}}</a>
    </div>
  </div>
</template>

<script>
import Tracker from "@/utils/tracker";
export default {
  name: 'Header',
  props: {
    active: {
      type: Number,
      default: 0
    },
    path: {
      type: String,
      default: 'index'
    },
  },
  data() {
    return {
      navList: [],
      showBG: false,
      lang: this.$i18n.locale,
      token:undefined,
      internalAudio: this.audio 
    }
  },
  created() {
    this.navList = this.$t('navList');
  },
  watch: {
    '$i18n.locale': function(newVal) {
      this.lang = newVal;
      this.navList = this.$t('navList');
    }
  },
  methods: {
    handleScrollShowHeaderBG() {
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      this.showBG = scrollTop > 0;
    },
    getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
    },
    playaudio(bull){
      if(bull===this.internalAudio){
        return
      }else{
        this.internalAudio = bull
      }
      var audio = document.getElementById("audio");
      if(this.internalAudio){
        audio.play();
      }else{
        audio.pause();
      }
    },
    // 点击导航
    handleClickNav(index) {
      Tracker.handleClickNav_view()
      if (index === this.navList.length - 1) {
        this.token = this.getCookie("token");
        if(this.token != undefined){
          window.location.href = 'https://enbbs.yepboys.com/#/index?token=' + this.token ;
        }else{
          window.location.href = 'https://enbbs.yepboys.com/#/index' ;
        }
      } else {
        // 如果是当前页面则不做任何操作
        if (this.path === 'index' && index === this.active) return false;
        // 判断path是否是index，如果是则触发父组件的togglePage事件
        if (this.path === 'index') {
          this.$emit('togglePage', index);
        } else {
          // 如果不是index则跳转到index页面
          this.$router.push({path:'/', query: {path: index}});
        }
      }
    },
    // 点击切换语言
    changeLanguage() {
      // if (this.$i18n.locale === 'en') {
      //   this.$i18n.locale = 'zh-CN';
      // } else {
        // this.$i18n.locale = 'en';
        // https://xjtest.luckyxp.com.cn/#/?path=0
        Tracker.changeLanguage_view()
        localStorage.removeItem('loading');
        window.location.href ='https://ch.mmo.yepboys.com/'
      // }
      // 把当前语言存储到localStorage
      // localStorage.setItem('language', this.$i18n.locale);
      // 刷新页面
      // window.location.reload();
    }
  },
  mounted() {
    // 监听滚动事件
    window.addEventListener('scroll', this.handleScrollShowHeaderBG);
  }
}
</script>

<style scoped lang="scss">

.header {
  width: 100vw;
  position: fixed;
  top: 0;
  padding-top: vw(12);
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: vw(37);

  &.showBG {
    background: rgba(0, 0, 0, 0.6);
    box-shadow: 0 0 vw(20) rgba(0, 0, 0, 0.6);
    transition: all 0.2s;
    backdrop-filter: blur(10px);
  }

  .audio{
    position: absolute;
    right: vw(535);
    top: vw(-10);
      &.no{
        width: vw(120);
        height: vw(120);
        background: url(./images/musicicon_1.png) center/100%;
      }
      &.yes{
        width: vw(120);
        height: vw(120);
        // animation: center 2s both linear infinite;
        background: url(./images/musicicon_2.png) center/100%;
      }
    }

  .logo {
    width: vw(200);
    height: vw(67);
    background: url("./images/logo.png") no-repeat center/contain;
    margin-left: vw(30);
    cursor: default;
  }

  .nav {
    display: flex;
    justify-content: space-around;
    position: relative;

    &::before {
      content: '';
      width: vw(340);
      height: vw(3);
      background: url("./images/line-1.png") no-repeat center/contain;
      position: absolute;
      left: 0;
      top: 50%;
      //transform: translateY(-40%);
      pointer-events: none;
    }



    .nav-item {
      font-size: vw(16);
      color: #ccc;

      &.active {
        color: #64f5fb;
        position: relative;

        &::before {
          content:  '';
          width: vw(73);
          height: vw(46);
          background: url("./images/light.png") no-repeat center/contain;
          position: absolute;
          left: 50%;
          top: 50%;
          margin-left: vw(-36);
          margin-top: vw(-23);
          pointer-events: none;
        }

        &::after {
          content: '';
          width: vw(43);
          height: vw(1);
          background: url("./images/line-2.png") no-repeat center/contain;
          position: absolute;
          left: 50%;
          bottom: vw(-12);
          margin-left: vw(-22);
          pointer-events: none;
        }
      }

      &:hover {
        color: #64f5fb;
        position: relative;

        &::before {
          content:  '';
          width: vw(73);
          height: vw(46);
          background: url("./images/light.png") no-repeat center/contain;
          position: absolute;
          left: 50%;
          top: 50%;
          margin-left: vw(-36);
          margin-top: vw(-23);
          pointer-events: none;
        }

        &::after {
          content: '';
          width: vw(43);
          height: vw(1);
          background: url("./images/line-2.png") no-repeat center/contain;
          position: absolute;
          left: 50%;
          bottom: vw(-12);
          margin-left: vw(-22);
          pointer-events: none;
        }
      }
    }

    &.nav-en {
      width: vw(500);
      font-weight: bold;

      &::before {
        top: 46%;
      }

      .nav-item {
        font-size: vw(18);
      }

      .language {
        font-size: vw(16);
        padding-top: vw(2);
      }
    }

    &.nav-cn {
      width: vw(375);
    }

    .language {
      position: relative;
      font-size: vw(14);
      color: #64f5fb;

      &::before {
        content: '';
        width: vw(36);
        height: vw(37);
        background: url("./images/icon-language.png") no-repeat center/contain;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: vw(-18);
        margin-top: vw(-18);
        pointer-events: none;
      }

      &:hover {
        &::before {
          content: '';
          width: vw(80);
          height: vw(80);
          background: url("./images/icon-language2.png") no-repeat center/contain;
          margin-left: vw(-40);
          margin-top: vw(-40);
          pointer-events: none;
        }
      }
    }
  }
}

</style>
