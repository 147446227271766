<template>
  <div :class="['modal', 'introduce-modal', lang === 'en' && 'introduce-modal-en']">
    <a href="javascript:;" class="icon-close" @click="handleCloseModal" />
    <div class="modal-content">
      <!-- <h1 class="title" /> -->
      <img class="title" v-if="modalType===0" src="./images/en/tit1.png" alt="">
      <img class="title" v-else src="./images/en/tit2.png" alt="">
      <div class="desc" >
        <!-- <h2 class="tit">{{lang === 'en' ? desc[modalType].title : desc[modalType].title}}</h2> -->
        <p id="desc">{{lang === 'en' ? desc[modalType].desc : desc[modalType].desc}}</p>
        <!-- <p>{{lang === 'en' ? desc[modalType].desc : desc[modalType].desc}}</p> -->
      </div>
    </div>
  </div>
</template>


<script>
import {getconceptstation} from "@/api";
  export default {
  name: 'IntroduceModal',
  props: {
    modalType: {
      type: Number,
      default: 0
    },
    lang: {
      type: String,
      default: 'zh-CN'
    }
  },
  watch: {
    lang: function() {
      this.getconceptstation()
    }
  },
  data() {
    return {
      desc: [
        {
          title: '1品牌解析',
          desc: ''
        },
        {
          title: '2品牌解析',
          desc: ''
        }
      ],
      // descEN: [
      //   {
      //     title: '1Brand Analysis',
      //     desc: '1The "Star Jie Era" is a new generation MMORPG with a realistic style based on future science fiction. It focuses on visually expressing the ultra-avant-garde high-concept "cool" and "trendy" art style. As a project initiated by players, it will not attract players with exaggerated gimmicks such as "thousands of landable planets..." It provides players with the most suitable exploration elements for MMOs. Whether you are a lone wolf or a fixed team, you have your own way of evolution. The causes and consequences of civilizations buried in various corners of the universe are waiting for you to explore'
      //   },
      //   {
      //     title: '2Brand Analysis',
      //     desc: '2The "Star Jie Era" is a new generation MMORPG with a realistic style based on future science fiction. It focuses on visually expressing the ultra-avant-garde high-concept "cool" and "trendy" art style. As a project initiated by players, it will not attract players with exaggerated gimmicks such as "thousands of landable planets..." It provides players with the most suitable exploration elements for MMOs. Whether you are a lone wolf or a fixed team, you have your own way of evolution. The causes and consequences of civilizations buried in various corners of the universe are waiting for you to explore'
      //   }
      // ],
    }
  },
  methods: {
    handleCloseModal() {
      this.$emit('close', 'showIntroduceModal');
    },
    getconceptstation() {
      let params = null
      if (this.lang === 'en') {
        params = {
          lang: 'en'
        }
      }
      document.getElementById('desc').addEventListener('wheel', function(event) {
        // event.preventDefault(); // 阻止默认行为
        event.stopPropagation();
      }, { passive: false });
      getconceptstation(params).then(response => {
        if (response.code === 0) {
          console.log(response)
          this.desc[0].desc = response.data.item_list[0].world_outlook
          this.desc[1].desc = response.data.item_list[0].product_concept
        }
      })
    },
    mounted() {
      this.$nextTick(() => {
        this.getconceptstation()
      })
    },
    created(){
     
    
    }
  }
}
</script>

<style scoped lang="scss">
  .introduce-modal {
    width: vw(951);
    height: vw(493);
    background: url("./images/bg.png") no-repeat center/cover;
    padding: vw(45) vw(27) vw(22) vw(66);

    .icon-close {
      top: vw(30);
      right: vw(10);
    }

    .title {
      display: block;
      // background: url("./images/tit1.png") no-repeat center/contain;
      margin: 0 auto vw(20);
      transform: translateX(vw(-25));
    }

    &.introduce-modal-en {
      .title {
        height: vw(47);
        // background-image: url("./images/en/tit1.png");
      }
    }

    .desc {
      width: vw(740);
      height: vw(347);
      padding-right: vw(60);
      margin: 0 auto;
      overflow-y: auto;

      /* 滚动条整体样式 */
      &::-webkit-scrollbar {
        width: 4px; /* 滚动条宽度 */
      }

      /* 滚动条轨道 */
      &::-webkit-scrollbar-track {
        background-color: transparent; /* 轨道背景色 */
      }

      /* 滚动条滑块 */
      &::-webkit-scrollbar-thumb {
        background-color: #64f5fb; /* 滑块颜色 */
        border-radius: 2px; /* 滑块圆角 */
      }

      /* 滚动条滑块悬停状态 */
      &::-webkit-scrollbar-thumb:hover {
        background-color: #555; /* 滑块悬停状态颜色 */
      }


      .tit {
        font-size: vw(24);
        color: #fff;
        line-height: vw(42);
        text-align: center;
        position: relative;
        margin-bottom: vw(5);

        &::after {
          content: '';
          width: vw(30);
          height: vw(1);
          background: #63f6fb;
          position: absolute;
          bottom: 0;
          left: 50%;
          margin-left: vw(-15);
        }
      }

      p {
        line-height: vw(46);
        color: #fff;
        font-size: vw(18);
        white-space: pre-line;
      }

      img {
        display: block;
        max-width: 100%;
        margin: vw(12) auto;
      }
    }
  }
</style>
