var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['part', 'part-3', 'show']},[_c('div',{class:['main', _vm.isShow && 'space']},[_c('h1',{staticClass:"title"}),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShow),expression:"isShow"}],ref:"messageList",staticClass:"left",attrs:{"id":"left"}},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13),_vm._m(14),_vm._m(15),_vm._m(16),_vm._m(17),_vm._m(18),_vm._m(19),_vm._m(20),_vm._m(21),_vm._m(22),_vm._m(23),_vm._m(24),_vm._m(25),_vm._m(26),_vm._m(27)]),_c('div',{class:['right']},[_c('h2',{staticClass:"tit"}),_vm._m(28),_c('a',{staticClass:"btn-join",attrs:{"href":"javascript:;"},on:{"click":_vm.diahandleClickJoin}})])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('img',{attrs:{"src":require("./images/img-message1.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('img',{attrs:{"src":require("./images/img-message2.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('img',{attrs:{"src":require("./images/img-message3.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('img',{attrs:{"src":require("./images/img-message4.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('img',{attrs:{"src":require("./images/img-message5.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('img',{attrs:{"src":require("./images/img-message6.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('img',{attrs:{"src":require("./images/img-message7.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('img',{attrs:{"src":require("./images/img-message8.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('img',{attrs:{"src":require("./images/img-message9.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('img',{attrs:{"src":require("./images/img-message10.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('img',{attrs:{"src":require("./images/img-message11.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('img',{attrs:{"src":require("./images/img-message12.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('img',{attrs:{"src":require("./images/img-message13.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('img',{attrs:{"src":require("./images/img-message14.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('img',{attrs:{"src":require("./images/img-message15.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('img',{attrs:{"src":require("./images/img-message16.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('img',{attrs:{"src":require("./images/img-message17.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('img',{attrs:{"src":require("./images/img-message18.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('img',{attrs:{"src":require("./images/img-message19.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('img',{attrs:{"src":require("./images/img-message20.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('img',{attrs:{"src":require("./images/img-message21.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('img',{attrs:{"src":require("./images/img-message22.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('img',{attrs:{"src":require("./images/img-message23.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('img',{attrs:{"src":require("./images/img-message24.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('img',{attrs:{"src":require("./images/img-message25.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('img',{attrs:{"src":require("./images/img-message26.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('img',{attrs:{"src":require("./images/img-message27.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('img',{attrs:{"src":require("./images/img-message28.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"role-list"},[_c('li',{staticClass:"li"},[_c('img',{attrs:{"src":require("./images/icon-role1.png"),"alt":""}})]),_c('li',{staticClass:"li"},[_c('img',{attrs:{"src":require("./images/icon-role2.png"),"alt":""}})]),_c('li',{staticClass:"li"},[_c('img',{attrs:{"src":require("./images/icon-role3.png"),"alt":""}})]),_c('li',{staticClass:"li"},[_c('img',{attrs:{"src":require("./images/icon-role4.png"),"alt":""}})]),_c('li',{staticClass:"li"},[_c('img',{attrs:{"src":require("./images/icon-role5.png"),"alt":""}})]),_c('li',{staticClass:"li"},[_c('img',{attrs:{"src":require("./images/icon-role6.png"),"alt":""}})]),_c('li',{staticClass:"li"},[_c('img',{attrs:{"src":require("./images/icon-role7.png"),"alt":""}})]),_c('li',{staticClass:"li"},[_c('img',{attrs:{"src":require("./images/icon-role8.png"),"alt":""}})]),_c('li',{staticClass:"li"},[_c('img',{attrs:{"src":require("./images/icon-role9.png"),"alt":""}})])])
}]

export { render, staticRenderFns }