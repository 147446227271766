<template>
    <div :class="['modal', lang === 'en' ? 'join-discussion-modal-en' : 'join-discussion-modal']" >
      <a href="javascript:;" class="icon-close" @click="handleCloseModal"/>
      <div class="modal-content">
        <div class="title"></div>
        <div class="channel-list">
          <a v-for="(item, index) in channelList" :key="index"  @click="linkUrl(item)" href="javascript:;" :class="`icon-${item.icon}`" />
        </div>
        <a class="confirm" href="javascript:;" @click="handleCloseModal"></a>
      </div>
    </div>
</template>


<script>
  import Tracker from "@/utils/tracker";
  export default {
  name: 'joinDiscussion',
  props: {
    lang: {
      type: String,
      default: 'zh-CN'
    },
  },
  data() {
    return {
      channelList: [],
    }
  },
  created() {
    this.channelList = this.$t('channelList')
  },
  watch: {
    lang: function() {
      this.channelList = this.$t('channelList')
      this.ainIndex = 0
    }
  },
  methods: {
    handleCloseModal() {
      this.$emit('close', 'showIntroduceModal');
    },
    linkUrl(item){
      if(item.icon == 'qq' ||  item.icon == 'wx' ){
        return
      }else{
        Tracker.linkUrl_view()
        window.open(item.url)  
      }
    },
  },
  mounted(){
    console.log(this.lang)
  }
}
</script>

<style scoped lang="scss">
  .join-discussion-modal {
    width: vw(611);
    height: vw(333);
    background: url("./images/bg.png") no-repeat center/cover;
    padding: vw(45) vw(66) vw(22) vw(66);
    .icon-close {
      top: vw(30);
      right: vw(10);
    }
    .title {
      width: vw(130);
      height: vw(32);
      background: url("./images/tit1.png") no-repeat center/contain;
      margin: 0 auto vw(40);

    }
    .modal-content{
      width: vw(480);
      margin: vw(10) auto 0;
    }
    .channel-list {
      width: vw(350);
      height: vw(115);
      margin: vw(-25) auto vw(-0);
      position: relative;
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: space-around;

      a {
        display: block;
      }

      .icon-wx {
        width: vw(31);
        height: vw(25);
        background: url("./images/icon-wx.png") no-repeat center/contain;

        &:hover {
          width: vw(80);
          height: vw(80);
          background: url("./images/wx-qrcode.png") no-repeat center/contain;
        }
      }

      .icon-qq {
        width: vw(23);
        height: vw(27);
        background: url("./images/icon-qq.png") no-repeat center/contain;

        &:hover{
          width: vw(80);
          height: vw(80);
          background: url("./images/qq-qrcode.png") no-repeat center/contain;
        }
      }

      .icon-wb {
        width: vw(31);
        height: vw(26);
        background: url("./images/icon-wb.png") no-repeat center/contain;

        &:hover {
          background: url("./images/icon-wb-active.png") no-repeat center/contain;
        }
      }

      .icon-dy {
        width: vw(26);
        height: vw(32);
        background: url("./images/icon-dy.png") no-repeat center/contain;

        &:hover {
          background: url("./images/icon-dy-active.png") no-repeat center/contain;
        }
      }

      .icon-ds {
        width: vw(23);
        height: vw(27);
        background: url("./images/en/icon-discord.png") no-repeat center/contain;

        &:hover {
          background: url("./images/en/icon-discord-active.png") no-repeat center/contain;
        }
      }

      .icon-x {
        width: vw(23);
        height: vw(27);
        background: url("./images/en/icon-twitter.png") no-repeat center/contain;

        &:hover {
          background: url("./images/en/icon-twitter-active.png") no-repeat center/contain;
        }
      }

      .icon-yt {
        width: vw(23);
        height: vw(27);
        background: url("./images/en/icon-youtube.png") no-repeat center/contain;

        &:hover {
          background: url("./images/en/icon-youtube-active.png") no-repeat center/contain;
        }
      }

      .icon-tt {
        width: vw(26);
        height: vw(32);
        background: url("./images/en/icon-tiktok.png") no-repeat center/contain;

        &:hover {
          background: url("./images/en/icon-tiktok-active.png") no-repeat center/contain;
        }
      }
    }
    .confirm{
      width: vw(167);
      height: vw(76);
      display: block;
      margin: 0 auto;
      background: url("./images/confirm.png") no-repeat center/contain;
    }
  }

  .join-discussion-modal-en {
    width: vw(611);
    height: vw(333);
    background: url("./images/bg.png") no-repeat center/cover;
    padding: vw(45) vw(66) vw(22) vw(66);
    .icon-close {
      top: vw(30);
      right: vw(10);
    }
    .title {
      width: vw(179);
      height: vw(25);
      background: url("./images/en/tit1.png") no-repeat center/contain;
      margin: 0 auto vw(40);
    }
    .modal-content{
      width: vw(480);
      margin: vw(10) auto 0;
    }
    .channel-list {
      width: vw(286);

      margin: vw(20) auto 0;
      position: relative;
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: vw(40);

      a {
        display: block;
      }

      .icon-wx {
        width: vw(31);
        height: vw(25);
        background: url("./images/icon-wx.png") no-repeat center/contain;

        &:hover {
          background: url("./images/icon-wx-active.png") no-repeat center/contain;
        }
      }

      .icon-qq {
        width: vw(23);
        height: vw(27);
        background: url("./images/icon-qq.png") no-repeat center/contain;

        &:hover {
          background: url("./images/icon-qq-active.png") no-repeat center/contain;
        }
      }

      .icon-wb {
        width: vw(31);
        height: vw(26);
        background: url("./images/icon-wb.png") no-repeat center/contain;

        &:hover {
          background: url("./images/icon-wb-active.png") no-repeat center/contain;
        }
      }

      .icon-dy {
        width: vw(26);
        height: vw(32);
        background: url("./images/icon-dy.png") no-repeat center/contain;

        &:hover {
          background: url("./images/icon-dy-active.png") no-repeat center/contain;
        }
      }

      .icon-ds {
        width: vw(23);
        height: vw(27);
        background: url("./images/en/icon-discord.png") no-repeat center/contain;

        &:hover {
          background: url("./images/en/icon-discord-active.png") no-repeat center/contain;
        }
      }

      .icon-x {
        width: vw(23);
        height: vw(27);
        background: url("./images/en/icon-twitter.png") no-repeat center/contain;

        &:hover {
          background: url("./images/en/icon-twitter-active.png") no-repeat center/contain;
        }
      }

      .icon-yt {
        width: vw(23);
        height: vw(27);
        background: url("./images/en/icon-youtube.png") no-repeat center/contain;

        &:hover {
          background: url("./images/en/icon-youtube-active.png") no-repeat center/contain;
        }
      }

      .icon-tt {
        width: vw(26);
        height: vw(32);
        background: url("./images/en/icon-tiktok.png") no-repeat center/contain;

        &:hover {
          background: url("./images/en/icon-tiktok-active.png") no-repeat center/contain;
        }
      }
    }
    .confirm{
      width: vw(167);
      height: vw(76);
      display: block;
      margin: 0 auto;
      background: url("./images/en/confirm.png") no-repeat center/contain;
    }
  }
</style>
