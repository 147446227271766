import Vue from 'vue'
import VueI18n from 'vue-i18n';
import messagesEn from '@/locales/en.json';
import messagesZhCN from '@/locales/zh-CN.json';
import App from './App.vue'
import router from './router';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import Swiper from 'swiper';
import 'swiper/js/swiper.esm.js';
import 'swiper/css/swiper.min.css';

Vue.config.productionTip = false
// 引入全局样式
import '@/assets/styles/reset.css';

Vue.use(VueI18n);

let currentLanguage;

// 获取浏览器语言
const language = navigator.language.toLowerCase();
const lang = language === 'zh-cn' ? 'zh-CN' : 'en';

if (localStorage.getItem('language')) {
    currentLanguage = localStorage.getItem('language');
} else {
    currentLanguage = lang;
}


const i18n = new VueI18n({
  locale: currentLanguage, // 默认语言
  messages: {
    en: messagesEn,
    'zh-CN': messagesZhCN,
  },
});

Vue.prototype.$currentLanguage = i18n.locale;
window.currentLanguage = Vue.prototype.$currentLanguage;

Vue.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true,
  position: "top-center",
  timeout: 3000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: true,
  hideProgressBar: true,
  closeButton: "button",
  icon: false,
  rtl: false
});

Vue.use({
  install(Vue) {
    Vue.prototype.$swiper = Swiper;
  }
});

new Vue({
  i18n,
  router,
  render: h => h(App),
  created() {
    // 监听语言变化
    this.$i18n.locale = 'en';
    // this.$i18n._vm.$watch('locale', (newVal) => {
    //   this.$currentLanguage = newVal;
    //   window.currentLanguage = newVal;
    // });
  },
}).$mount('#app')
