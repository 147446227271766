<template>
  <div :class="['modal', 'login-modal', lang === 'en' && 'login-modal-en']">
    <a href="javascript:;" class="icon-close" @click="handleCloseModal"/>
    <div class="modal-content">
      <div v-if="lang !== 'en'" class="method">
        <a href="javascript:;" :class="['method-item', loginData.method === 'phone' && 'active']"
           @click="handleToggleMethod('phone')">{{$t('modal.phone')}}</a>
        <a href="javascript:;" :class="['method-item', loginData.method === 'email' && 'active']"
           @click="handleToggleMethod('email')">{{$t('modal.mail')}}</a>
      </div>
      <div v-if="lang === 'en'" class="method">
        <!-- <a href="javascript:;" :class="['method-item', loginData.method === 'email' && 'active']"
           @click="handleToggleMethod('email')">{{$t('modal.mail')}}</a> -->
      </div>
      <input v-if="loginData.method === 'phone'" class="input input-phone" maxlength="14" type="text"
             v-model="loginData.phone" :placeholder="$t('modal.phonePlaceholder')" @input="handleInputChange"/>
      <input v-if="loginData.method === 'email'" class="input input-phone" type="text" v-model="loginData.email"
             :placeholder="$t('modal.mailPlaceholder')"/>
      <div class="code">
        <input class="input input-code" type="text" v-model.number="loginData.code" :placeholder="$t('modal.codePlaceholder')"
               @input="handleInputChange"/>
        <a href="javascript:;" :class="['btn-getCode', getCountdown !== 60 && 'disabled']"
           @click="handleGetCode">{{ getCountdown === 60 ? $t('modal.getCode') : `${getCountdown}S` }}</a>
      </div>
      <div class="protocol">
        <span class="checkbox" @click="handleConfirmProtocol">
          <i v-if="isConfirmProtocol" class="icon-check" />
        </span>
        <p v-if="lang === 'zh-CN'">我已经阅读并同意遵守相关<a href="javascript:;" class="link" @click="handleProtocol(4)">服务条款</a>和<a href="javascript:;" class="link" @click="handleProtocol(5)">隐私协议</a></p>
        <p v-if="lang === 'en'">I have read and agree to the<a href="javascript:;" class="link" @click="handleProtocol(4)"> terms and conditions</a> and <a href="javascript:;" class="link" @click="handleProtocol(5)">Privacy Policy</a></p>
      </div>
      <a href="javascript:;" class="btn-login" @click="handleLogin"/>
    </div>
  </div>
</template>


<script>
import {isEmail, isPhone, setCookie} from "@/utils/utils";
import {getEmailCode, getPhoneCode, login} from "@/api/main";
import Tracker from "@/utils/tracker";
export default {
  name: 'LoginModal',
  props: {
    isConfirmProtocol: {
      type: Boolean,
      default: false
    },
    lang: {
      type: String,
      default: 'zh-CN'
    }
  },
  data() {
    return {
      getCountdown: 60,
      loginData: {
        method: this.lang === 'en' ? 'email' : 'phone',
        phone: null,
        email: '',
        code: null
      }
    }
  },
  mounted() {
    // 判断localeStorage中是否有倒计时的值
    if (localStorage.getItem('countdown')) {
      this.getCountdown = localStorage.getItem('countdown');
      this.countdown()
    }
  },
  methods: {
    handleCloseModal() {
      this.$emit('close', 'showLoginModal');
    },
    // 点击同意协议
    handleConfirmProtocol() {
      this.$emit('toggleProtocol')
    },
    // 点击获取验证码
    handleGetCode() {
      if (this.getCountdown !== 60) {
        return false;
      }
      Tracker.handleGetCode_view()
      if (this.loginData.method === 'phone') {
        if (this.loginData.phone === null || this.loginData.phone === '') {
          this.$toast.error(this.$t('modal.phonePlaceholder'));
        } else if (!isPhone(this.loginData.phone)) {
          this.$toast.error(this.$t('modal.phoneError'));
        } else {
          // 开始倒计时
          this.countdown();
          // 获取验证码
          this.getPhoneCode();
        }
      } else {
        if (this.loginData.email === null || this.loginData.email === '') {
          this.$toast.error(this.$t('modal.mailPlaceholder'));
        } else if (!isEmail(this.loginData.email)) {
          this.$toast.error(this.$t('modal.mailError'));
        } else {
          // 开始倒计时
          this.countdown();
          // 获取验证码
          this.getEmailCode();
        }
      }
    },
    // input输入框改变
    handleInputChange(event) {
      // 如果输入的不是数字，则禁止输入,并且清除非数字的字符，只保留数字，输入空格也会被清除
      event.target.value = event.target.value.replace(/[^\d]/g, '');
    },
    // 切换登录方式
    handleToggleMethod(method) {
      Tracker.handleToggleMethod_view()
      this.loginData.method = method;
    },
    // 倒计时
    countdown() {
      if (this.getCountdown === 0) {
        this.getCountdown = 60;
        localStorage.removeItem('countdown');
        return false;
      } else {
        this.getCountdown--;
        localStorage.setItem('countdown', this.getCountdown);
        setTimeout(() => {
          this.countdown();
        }, 1000);
      }
    },
    // 获取手机号码验证码
    getPhoneCode() {
      const params = {
        phone: this.loginData.phone.toString()
      };
      Tracker.getPhoneCode_view()
      getPhoneCode(params).then(response => {
        console.log(response);
      });
    },
    // 获取邮箱验证码
    getEmailCode() {
      const params = {
        mail: this.loginData.email
      };
      Tracker.getEmailCode_view()
      getEmailCode(params).then(response => {
        console.log(response);
      });
    },
    // 登录
    handleLogin() {
      // 判断是否同意协议
      if (!this.isConfirmProtocol) {
        this.$emit('openProtocol', 'login-modal');
        return false;
      }
      let params = {};
      // 判断验证码是否已经填写
      if (this.loginData.code === null || this.loginData.code === '') {
        this.$toast.error(this.$t('modal.codePlaceholder'));
        return false;
      }
      Tracker.login_view()
      // 判断手机号码是否已经填写并且是正确的手机号码
      if (this.loginData.method === 'phone') {
        if (this.loginData.phone === null || this.loginData.phone === '') {
          this.$toast.error(this.$t('modal.phonePlaceholder'));
          return false;
        } else if (!isPhone(this.loginData.phone)) {
          this.$toast.error(this.$t('modal.phoneError'));
          return false;
        } else {
          params = {
            user: this.loginData.phone.toString(),
            code: this.loginData.code.toString(),
            type: 1
          };
        }
      } else {
        if (this.loginData.email === null || this.loginData.email === '') {
          this.$toast.error(this.$t('modal.mailPlaceholder'));
          return false;
        } else if (!isEmail(this.loginData.email)) {
          this.$toast.error(this.$t('modal.mailError'));
          return false;
        } else {
          params = {
            user: this.loginData.email,
            code: this.loginData.code.toString(),
            type: 2
          };
        }
      }
      // 登录
      login(params).then(response => {
        if (response.code === 0) {
          this.$toast.success(this.$t('modal.loginSuccess'));
          this.$emit('close', 'showLoginModal');
          setCookie('token', response.data.auth.token, response.data.auth.expire);
          setCookie('id', response.data.user.id, response.data.auth.expire);
          setCookie('name', response.data.user.name, response.data.auth.expire);
          this.$emit('toggleOrder', response.data.user.is_reservation, response.data.auth.expire);
          if (this.lang !== 'en') {
            // 判断是否填写了身份信息
            if (response.data.user.is_verify === 0) {
              // 跳转到填写身份信息页面
              this.handleCloseModal();
              this.$emit('openRealName');
            }
          }
        } else {
          this.$Toast.error(response.msg);
        }
      });
    },
    // 点击协议
    handleProtocol(type) {
      // 跳转到协议页面
      // const url = this.$router.resolve({path:'/details', query: {type: type}})
      // window.open(url.href, '_blank')
      Tracker.handleProtocol_view()
      this.$router.push({ path: '/details', query: { type: type } })
    }
  }
}
</script>

<style scoped lang="scss">
.login-modal {
  width: vw(611);
  height: vw(483);
  background: url("./images/bg.png") no-repeat center/cover;
  padding: vw(113) vw(67) vw(22) vw(66);

  .icon-close {
    top: vw(41);
    right: vw(86);
  }

  .method {
    width: vw(320);
    height: vw(25);
    // margin: 0 auto vw(31);
    display: flex;
    justify-content: space-between;
    align-items: center;

    .method-item {
      height: 100%;
      width: vw(150);
      border: vw(1) solid #418c95;
      background: #355d67;
      text-align: center;
      line-height: vw(39);
      font-size: vw(16);
      color: #86999e;
      font-weight: bold;

      &.active, &:hover {
        border-color: #64f5fb;
        background: #43929b;
        color: #fff;
        box-shadow: 0 vw(4) vw(10) rgba(69, 144, 153, 0.6);
      }
    }
  }

  .input {
    height: vw(44);
    border: vw(1) solid #418d95;
    line-height: vw(42);
    font-size: vw(16);
    padding-left: vw(15);
    background: transparent;
    color: #fff;
    display: block;

    &::placeholder {
      color: #999;
    }

    &.input-phone {
      width: vw(380);
      margin: 0 auto vw(20);
    }

    &.input-code {
      width: vw(220);
    }
  }

  .code {
    width: vw(380);
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .btn-getCode {
      width: vw(150);
      height: vw(44);
      border: vw(1) solid #64f5fb;
      background: #418c95;
      font-size: vw(16);
      text-align: center;
      line-height: vw(42);
      font-weight: bold;
      color: #fff;

      &.disabled {
        filter: grayscale(1);
        pointer-events: none;
      }
    }
  }

  .protocol {
    width: vw(380);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: vw(28) auto;

    .checkbox {
      width: vw(20);
      height: vw(20);
      border: vw(1) solid #64f5fb;
      border-radius: 50%;
      margin-right: vw(6);
      display: flex;
      justify-content: center;
      align-items: center;

      .icon-check {
        width: vw(11);
        height: vw(8);
        background: url("./images/icon-right.png") no-repeat center/contain;
      }
    }

    p {
      font-size: vw(16);
      color: #fff;

      a {
        color: #62eff6;
      }
    }
  }

  .btn-login {
    display: block;
    width: vw(167);
    height: vw(76);
    background: url("./images/btn-login.png") no-repeat center/contain;
    margin: vw(20) auto 0;
    position: relative;
  }

  &.login-modal-en {
    background-image: url("./images/en/bg.png");

    .btn-login {
      background-image: url("./images/en/btn-login.png");
    }

    .method {
      width: vw(420);
      justify-content: center;

      .method-item {
        height: 100%;
        width: vw(200);
        font-size: vw(14);
      }
    }

    .input {
      width: vw(420);

      &::placeholder {
        color: #fff;
        opacity: 0.6;
        font-size: vw(14);
      }

      &.input-code {
        width: vw(240);
        margin: 0;
      }
    }

    .code {
      width: vw(420);


      .btn-getCode {
        width: vw(160);
        font-size: vw(14);
      }
    }

    .protocol {
      width: vw(420);
      margin: vw(20) auto;
      p {
        width: 95%;
      }
    }
  }
}
</style>
