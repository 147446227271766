<template>
  <div class="modal video-modal">
    <a href="javascript:;" class="icon-close" @click="handleCloseModal"/>
    <div class="modal-content">
      <video ref="videoDom" :src="`https://${link}`" controls="controls" width="100%" height="100%"/>
    </div>
  </div>
</template>


<script>

export default {
  name: 'VideoModal',
  props: {
    link: {
      type: String,
      default: ''
    }
  },
  data() {
    return {

    }
  },
  mounted() {
    // 弹窗打开时，播放视频
    this.$refs.videoDom.play();
  },
  methods: {
    handleCloseModal() {
      this.$emit('close', 'showVideoModal');
    },

  }
}
</script>

<style scoped lang="scss">
.video-modal {
  width: vw(929);
  height: vw(493);
  background: url("./images/bg.png") no-repeat center/cover;
  padding: vw(17) vw(62) vw(22) vw(66);

  .icon-close {
    top: vw(-40);
    right: vw(10);
  }

  .modal-content {
    width: 100%;
    height: 100%;
    clip-path: polygon(
            0 vw(7),
            vw(10) 0,
            calc(100% - vw(7)) 0,
            100% vw(7),
            100% calc(100% - vw(7)),
            calc(100% - vw(7)) 100%,
            vw(10) 100%,
            0 calc(100% - vw(7))

    );
    overflow: hidden;
  }
}
</style>
