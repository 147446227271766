import gsap from "gsap";

export function handleHoverBtn(dom) {
    const tur = document.querySelector('feTurbulence')
    const btn = document.querySelectorAll(dom)

    // 创建gsap时间线
    const tl = new gsap.timeline({
        // 重复次数
        repeat: 1,
        // 暂停
        paused: true,
        onUpdate() {
            tur.setAttribute('baseFrequency', `0 ${val.freq}`)
        }
    })
    const val = {
        freq: 0.0001
    }
    tl.to(val, {
        freq: 0.6,
        duration: 0.1,
    })
    tl.to(val, {
        freq: 0.0001,
        duration: 0.1,
    })
    // 创建用于恢复按钮状态的时间线
    const restoreTl = new gsap.timeline({
        // 暂停
        paused: true,
        onUpdate() {
            tur.setAttribute('baseFrequency', `0 ${val.freq}`);
        }
    });
    restoreTl.to(val, {
        freq: 0.0001,
        duration: 0.1,
    });

    // 给所有的按钮添加事件
    for (let i = 0; i < btn.length; i++) {
        btn[i].onmouseenter = () => {
            tl.restart(); // 从时间线的起始位置开始播放动画
        };
        btn[i].onmouseleave = () => {
            restoreTl.restart(); // 恢复按钮状态到动画开始时的样子
        };
    }
}
