// 正则验证手机号码格式是否为1开头的11位数字
export const isPhone = (value) => {
  return /^1\d{10}$/.test(value)
}

// 正则验证邮箱格式
export const isEmail = (value) => {
  return /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/.test(value)
}

// 验证身份证号码
export const isIdCard = (value) => {
  return /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(value)
}

// 时间戳转换 1617292800 => 2021-04-01
export const parseTime = (time) => {
  const date = new Date(time * 1000)
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  return `${year}-${month}-${day}`
}

// 设置cookie，name为键，value为值，days为过期时间
export const setCookie = (name, value, days) => {
  const date = new Date(days * 1000);
  const gmtString = date.toUTCString();
  document.cookie = `${name}=${value};expires=${gmtString};path=/`
}

// 获取cookie
export const getCookie = (name) => {
  const cookie = document.cookie
  const arr = cookie.split('; ')
  for (let i = 0; i < arr.length; i++) {
    const newArr = arr[i].split('=')
    if (newArr[0] === name) {
      return newArr[1]
    }
  }
  return false
}
